<div class="file-preview-modal-card p-0 custom-height">
    <div class="file-preview-modal-custom-background d-flex flex-column justify-content-center">
        <div class="container common-title">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <label class="text-dark fw-600 fs-20" [innerHtml]="staticText?.common?.file_header"></label>
               
                <p class="mb-0 py-1 px-2">
                  <i (click)="previewInNewWindow()" class="cursor-pointer m-4 fa-solid fa-arrow-up-right-from-square"></i>
                    <img src="/assets/images/common/icons/close_black.svg" alt="close_black" class="cursor-pointer" (click)="dialogClose()">
                  </p>

                  
            </div>
        </div>
    </div>
    <div class="file-preview-modal-container">
      <ng-container *ngIf="showWithoutImg else img">
        <ng-container *ngIf="downloadUrl">
          <div class="d-flex justify-content-center align-items-center" *ngIf="!spinnerHide">
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ngx-doc-viewer [url]="downloadUrl" viewer="google" style="width:100%;height: 85vh;" 
            [googleCheckInterval]="7000" [googleMaxChecks]="5" (loaded)="onIframeLoaded()" >
          </ngx-doc-viewer>
        </ng-container>
      </ng-container>
  
      <ng-template #img>
       <div class="img-upload">
        <img [src]="(downloadUrl | updateAttachementFileUrl)" alt="" class="img-width w-100 my-2" />
       </div>
      </ng-template>

      <div class="common-modal-actions d-flex flex-row justify-content-between cursor-pointer px-4 py-3 fs-16">
        <div class="common-modal-close-button px-2">
            <button class="btn_default_user" mat-stroked-button (click)="dialogClose()"
                [innerHtml]="staticText?.common?.cancel_button"></button>
        </div>
        <div class="common-modal-event-button px-2 pagination">
            <img class="cursor-pointer previous_page_rotate" *ngIf="currentAttachmentIndex > 0" src="assets/images/common/icons/next_black.svg" alt="PreviousPage" (click)="previousPage()">
        <p class="mb-0 px-3">
             {{ currentAttachmentIndex + 1 }} of {{ totalAttachments }}
          </p>
        <img class="cursor-pointer next_page_rotate" *ngIf="currentAttachmentIndex < totalAttachments - 1" src="assets/images/common/icons/previous_black.svg" alt="NextPage" (click)="nextPage()">
        </div>
    </div>
   
      
    </div>
</div>
  