{
  "common": {
    "back_to_login_label":"Back to Login",
    "company_secondary_name" : "Rwanda Digital Tax Stamp System",
    "upload_copy": "Upload Cerificate Copy",
    "upload_browser": "Browse",
    "report": "Report",
    "file_header": "File Preview",
    "folder_name_label": "Folder Name",
    "upload_file_label": "Upload File",
    "permission_denied": "You do not have permission to access this resource",
    "action": "Action",
    "assigned_to": "Assigned to",
    "recommended_to": "Forwarded to",
    "scheduled_on": "Scheduled on",
    "invalid_otp": "Invalid OTP",
    "req_filed": "Required Field!",
    "enter_cmds": "Enter Your comments here",
    "download_template": "Download",
    "default_color_code": "#7C7C7C",
    "enter_sign": "Enter Your signature here",
    "loadMore": "Load More",
    "add_button": "Add",
    "add_button_user": "Add User",
    "comments": "Comments",
    "add_comments": "Add Comment",
    "your_comments_here": "Your comments here...",
    "done_button": "Done",
    "showHistoryBtn": "Status History",
    "cancel_button": "Cancel",
    "close_button_view": "Close",
    "update_button": "Update",
    "scan_button": "Scan Document",
    "document": "Document",
    "create_button": "Create",
    "choose_file_button": "Choose File",
    "attachment": "Attachment",
    "no_comments_found": "No Comments Found",
    "assign_button": "Assign",
    "reassign_button": "Reassign",
    "recommended_button": "Forward",
    "save_next_button": "Save & Next",
    "update_next_button": "Update & Next",
    "save_button": "Save",
    "search": "Search",
    "edit": "Edit",
    "view": "View",
    "delete": "Delete",
    "status_input": "Status",
    "search_keyword": "Search Keywords",
    "close_button": "Close",
    "edit_button": "Edit",
    "submit_button": "Submit",
    "submit_button_txt": "Request Reset Link",
    "submit_button_reset": "Reset Password",
    "upload_button": "Upload",
    "reject_button": "Reject",
    "defer_button": "Defer",
    "verify_button": "Verify",
    "select_button": "Select",
    "approve_button": "Approve",
    "proceed_button": "Proceed",
    "previous_button": "Previous",
    "next_button": "Next",
    "save_as_draft_button": "Save as Draft",
    "skip_now_button": "Skip for now",
    "apply_button": "Apply",
    "clear_button": "Clear",
    "t_and_c": "Terms and Conditions",
    "select_all": "Select All",
    "no_record": "No Records Found",
    "read_more": "...Read More",
    "read_less": "...Read Less",
    "publish_button": "Publish",
    "ignore_button": "Ignore",
    "score_validation": "A given score is higher than the checklist score",
    "bidding_amount_validation": "Bidding Amount required",
    "attachhment_required": "Attachment Required",
    "minimum_requirement": "Minimum Requirement",
    "comments_required": "Comment Required",
    "active": "Active",
    "inactive": "Inactive",
    "country_code": "+250",
    "select_placeholder": "Select",
    "rate_ugx": "UGX",
    "percentage": "%",
    "browser":"Browser",
    "date_range": "Date Range",
    "accepted_file_types": [".xls", ".xlxs", ".doc", ".pdf", "pptx"],
     "file_error_msg":"Only files up to 5 MB are supported for upload.",
    "modal": {
      "common_modal_pop_up": {
        "close_button": "Cancel",
        "create_button": "Create"
      },
      "success_modal_pop_up": {
        "title": "Success",
        "icon": "success",
        "paragraph": "Created successfully",
        "close_button": "Done"
      },
      "warning_modal_pop_up": {
        "title": "Are you sure?",
        "icon": "alert",
        "paragraph": "",
        "close_button": "Cancel",
        "main_event_button": "yes"
      },
      "delete_modal_pop_up": {
        "title": "Are you sure?",
        "icon": "delete",
        "paragraph": "",
        "close_button": "Cancel",
        "main_event_button": "yes, delete"
      }
    },
    "status_options": [
      {
        "name": "Active",
        "value": true
      },
      {
        "name": "Inactive",
        "value": false
      }
    ],
    "table_common_properties": {
      "table_addtional_grid_options": {
        "editable": false,
        "pagination": true,
        "enablePivot": true,
        "enableValue": true,
        "cacheBlockSize": 5,
        "paginationPageSize": 5,
        "headerCheckboxSelection": true,
        "suppressRowTransform": true,
        "table_default_grid_options": {}
      }
    },
    "table_filter": {
      "table_filter_title": "Filter",
      "table_filter_button_apply": "Apply filter",
      "table_filter_button_clear": "Clear"
    },
    "custom_table_properties": {
      "custom_table_pagination_block": {
        "custom_table_page_size": {
          "startRow": 0,
          "endRow": 10,
          "customSize": 5,
          "custom_table_page_size_label_displaying": "Showing",
          "custom_table_page_size_label_of": "of",
          "custom_table_page_size_label_totalRecords": "records",
          "custom_table_page_size_options": [
            {
              "name": "5",
              "value": 5
            },
            {
              "name": "10",
              "value": 10
            },
            {
              "name": "20",
              "value": 20
            },
            {
              "name": "50",
              "value": 50
            },
            {
              "name": "100",
              "value": 100
            },
            {
              "name": "150",
              "value": 150
            }
          ]
        },
        "custom_table_pagination": {
          "custom_table_pagination_label_page": "Page",
          "custom_table_pagination_label_of": "of"
        }
      }
    },
    "common_inputs": {
      "input_password": "Password",
      "input_confirm_password": "Confirm Password",
      "input_reset_password_strength_label": [
        "Very Poor",
        "Very Poor",
        "Poor",
        "Average",
        "Average",
        "Strong",
        "Very Strong"
      ]
    }
  },
  "mail": {
    "title": "Send mail",
    "to": "To",
    "cc": "Cc",
    "subject": "Subject",
    "send": "Send",
    "warning": "This message must have at least one recipient.",
    "subject_warning": "Enter the mail subject.",
    "content_warning": "Enter the mail content."
  },
  "error_pages": {
    "page_not_found": {
      "page_not_found_title": "404",
      "page_not_found_text": "Page Not Found!",
      "page_not_found_para": "We're sorry, the page you requested could not be found. Please go back to the homepage!",
      "page_not_found_route_button": "Homepage"
    },
    "access_forbidden": {
      "page_not_found_title": "403",
      "page_not_found_text": "Access Forbidden!",
      "page_not_found_para": "We're sorry, you don't have access this requested page.",
      "page_not_found_route_button": "Back to previous"
    },
    "internal_server_error": {
      "internal_server_error_title": "500",
      "internal_server_error_text_1": "Internal Server Error",
      "internal_server_error_text_2": "Sorry, Unexpected Error",
      "internal_server_error_para": "We are working on fixing the problem. Be back soon!"
    }
  },
  "spinner_properties": {
    "spinner_color": "accent",
    "spinner_mode": "indeterminate",
    "spinner_bg_color": "rgba(74, 74, 74, .1)"
  },
  "forgot_password": {
    "forgot_password_title_1": "Forgot Password",
    "forgot_password_paragraph_1": "Enter your registered email id to receive a password reset link.",
    "forgot_password_paragraph_2": "A One Time Password (OTP) will be sent on your registered Mobile Number / e-mail ID",
    "forgot_password_verified_success_msg": "OTP Verification Successful.",
    "forgot_password_paragraph_4": "Temporary password has been sent to your registered e-mail ID.",
    "forgot_password_paragraph_3": "Click here to redirect the reset password page",
    "forgot_password_input": "Email",
    "forgot_password_verify_button": "Verify",
    "forgot_password_resend_button": "Re-Send Otp",
    "forgot_password_anchor_button": "Back to Login",
    "forgot_password_anchor_button_Redirect": "Reset Password",
    "forgot_password_paragraph_5": "Click here to redirect the login page"
  },
  "reset_password": {
    "reset_link_label":"Your Link already Expired",
    "reset_password_label":"Reset Password",
    "reset_password_title": "Reset Password",
    "reset_password_paragraph": "Your password must contain minimum of 8 - 12 characters using <span class='fw-bold text-black'>( symbol, lowercase, uppercase characters, numbers etc. like for e.g. Aj7F!9m )</span>",
    "reset_password_input_1": "New Password",
    "reset_password_input_2": "Confirm Password",
    "reset_password_strength_label": [
      "Very Poor",
      "Very Poor",
      "Poor",
      "Average",
      "Average",
      "Strong",
      "Very Strong"
    ],
    "reset_password_link_expired_msg": "Link already used or expired",
    "reset_password_back_to_login_button": "Back to Login"
  },
  "login": {
    "login_welcome_msg": "Welcome!",

    "login_page_description": "Digital Tax Stamp Management System",
    "Rwanda_taxstamp_img": "/assets/images/common/login_page_usp/Rwanda_logo.png",
    "logo_txt": "Rwanda Taxstamp DTS",
    "login_label": "Login",
    "otp_label": "OTP",
    "login_description": "Enter your credentials to log in",
    "login_button_text": "Login",
    "otp_button_text": "Proceed",
    "login_page":"Go to Login",
    "login_heading": "LOGIN",
    "login_forgot_password_text": "Forgot Password?",
    "login_field_1": "Username",
    "login_field_2": "Password",

    "login_greet_msg": "",
    "login_greet_description": "Have all your contracts in one place, access them instantly from anywhere, get automatically notified of key dates and tasks, and uncover insights about the financial health of your contracts.",
    "login_copyrights": "All Copyrights reserved",
    "login_copyrights_year": "© ",

    "login_sub_heading": "Use your credentials to access your account.",
    "login_otp_heading": "VERIFY OTP",
    "login_otp_sub_heading": "Enter the OTP that has been sent to the authenticator app",
    "login_otp_btn": "Verify & Login",

    "login_forgot_button": "Forgot Password?",
    "login_button": "Continue",
    "login_anchor_button_1_text": "Don't have an account?",
    "login_anchor_button_1": "Signup",
    "login_anchor_button_2_text_suffix": "First time user? Click",
    "login_anchor_button_2": "here",
    "login_anchor_button_2_text_prefix": "to signup",
    "login_landing_redirect_button_text": "Back to Website",
    "sign_up": "Signup"
  },
  "header": {
    "Rwanda_group_img": "/assets/images/common/login_page_usp/Rwanda_logo.png",
    "Rwanda_Tax_Stamp_System_icn": "/assets/images/common/icons_usp/help.svg",
    "header_title": "Joint Qualification System",
    "header_button_1": "Logout",
    "header_button_2": "Act as Buyer"
  },
  "sidenav": {
    "parent_menu_1": "Document Management"
  },
  "footer": {
    "copyright_text": "PAU. All Rights Reserved."
  },
  "user_management": {
    "user": {
      "list": {
        "list_card_title": "User Management",
        "breadcrumbs":"Users",
        "new_user": "Add New User",
        "list_master_search": "Search",
        "list_button1": "Create User",
        "list_button2": "Download Users List",
        "table_fields": {
          "fullname": {
            "field": "Full Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key"  : "full_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "full_name"
              }
            }
          },
          "email": {
            "field": "Email",
            "view_Action": false,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "email"
              }
            }
          },
          "username": {
            "field": "Username",
            "view_Action": false,
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "username"
              }
            }
          },
          "user_type": {
            "field": "User Group",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "user_type__display_name"
              }
            }
          },
          "groups": {
            "field": "Role",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key": "groups__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "groups__name"
              }
            }
          },
         
          "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key" : "created_by",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key": "created_on",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "last_updated_by":{
            "field": "Last Updated By",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_sorting_key": "last_updated_by",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on":{
            "field": "Last Updated On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key": "last_updated_on",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "is_active": {
            "field": "Status",
            "column_options": {
              "column_maxWidth": 100,
              "column_sortable": false,
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_minWidth": 80
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "customclass": "role_width",
            "placeholder": "User Group",
            "type": "multidropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Role",
            "type": "multidropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
      },
      "view_edit": {
        "view_edit_page_title_1": "View User",
        "view_edit_page_title_2": "Edit User",
        "view_edit_page_title_3": "View User Details",
        "add_page_title_4": "Add User",
        "view_edit_input_1": "First Name",
        "view_edit_input_2": "Last Name",
        "view_edit_input_3": "Username",

        "view_edit_input_4": "Email",
        "view_edit_input_5": "Date of Birth",
        "view_edit_input_6": "Phone Number",
        "view_edit_input_7": "Region",
        "view_edit_input_8": "MZO",
        "view_edit_input_9": "District",
        "view_edit_input_10": "User Group",
        "view_edit_input_11": "Role",
        "view_edit_input_12": "MZO Valuer",
        "view_edit_input_13": "All User Group",
        "view_edit_input_14": "Status",
        "file_size_text": "Upload File",
        "file_upload_text": "Change Image",
        "file_upload_class": "custom-file-upload",
        "file_size_text_warn": "Please Upload File",
        "all_entity_enabled_label": "Enabled",
        "all_entity_disabled_label": "Disabled",
        "edit_user":"Update"
      }
    },
    "entity": {
      "list": {
        "list_card_title": "User Group",
        "list_master_search": "Search",
        "breadcrumbs": "Users Group",
        "new_user": "Add New User Group",
        "list_button1": "Add User Group",
        "list_button2": "Download Users List",
        "table_fields": {
          "entity_name": {
            "field": "User Group Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_sorting_key": "display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
            }
          },
          "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Updated By",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Updated On",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
             "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
         
          "is_active": {
            "field": "Status",
            "type": "custom_status",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": false,
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 100
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
      },
      "view": {
        "view_page_title": "View User Group",
        "view_page_label_1": "User Group Name",
        "view_page_label_2": "Created By",
        "view_page_label_3": "Created On",
        "view_page_label_4": "User Group ID",
        "view_page_label_5": "Description",
        "view_page_label_6": "Status"
      },
      "add": {
        "add_page_title": "Add User Group",
        "add_page_button": "Add User Group",
        "add_input_1": "User Group Name",
        "add_input_2": "User Group ID",
        "add_input_3": "Description",
        "add_input_4": "Address Line 1",
        "add_input_5": "Address Line 2",
        "add_input_6": "City",
        "add_input_7": "Province",
        "add_input_8": "Zipcode"
      },
      "edit": {
        "edit_page_title": "Edit User Group",
        "edit_page_button":"Edit User Group",
        "edit_input_1": "User Group Name",
        "edit_input_2": "User Group ID",
        "edit_input_3": "Status",
        "edit_input_4": "Description"
      }
    },
    "roles": {
      "list": {
        "list_card_title": "Roles",
        "list_master_search": "Search",
        "breadcrumbs": "Roles",
        "new_user": "Add New Role",
        "list_button1": "Add Roles",
        "list_button2": "Download Users List",
        "table_fields": {
          "role_name": {
            "field": "Roles",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key": "display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
            }
          },
          "user_type": {
            "field": "User Group",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key": "user_type__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "user_type__name"
              }
            }
          },
          "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key": "created_by",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key" :"created_on",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
            
          },
          "last_updated_by": {
            "field": "Last Updated By",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_sorting_key":"last_updated_by",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
          },
          "last_modified_on": {
            "field": "Last Updated On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "permission": {
            "field": "Permission",
            "type": "custom_status",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": false,
              "column_sorting_key": "permission"
            }
          },
          "is_active": {
            "field": "Status",
            "type": "custom_status",
            "column_options": {
              "column_maxWidth": 100,
              "column_sortable": false
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {}
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "customclass": "role_width",
            "placeholder": "User Group",
            "type": "multidropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
      },
      "view": {
        "view_page_title": "View Role",
        "view_page_title2": "Granted permissions",
        "view_page_subtitle1": "Menu",
        "view_page_subtitle2": "Action Allowed",
        "view_page_label_1": "User Group",
        "view_page_label_2": "Role Name",
        "view_page_label_3": "DoUser Managementmain",
        "view_page_label_4": "Workflow Management",
        "view_page_label_5": "Masters",
        "view_page_label_6": "Audit Reports",
        "view_page_label_7": "Dashboards",
        "view_page_label_8": "Reports",
        "view_page_label_9": "Description",
        "view_page_label_10": "Status"
      },
      "add": {
        "add_page_title": "New Role",
        "add_page_button": "Add Role",
        "add_page_title2": "Add Permissions",
        "add_page_subtitle1": "Menu",
        "add_page_subtitle2": "Action Allowed",
        "add_input_1": "User Group",
        "add_input_2": "Role Name",
        "add_input_3": "User Management",
        "add_input_4": "Workflow Management",
        "add_input_5": "Masters",
        "add_input_6": "Audit Reports",
        "add_input_7": "Dashboards",
        "add_input_8": "Reports",
        "add_input_9": "Description",
        "add_option_1": "Create",
        "add_option_2": "Read",
        "add_option_3": "Update",
        "add_option_4": "Delete"
      },
      "edit": {
        "edit_page_title": "Edit Role",
        "edit_page_button": "Edit Role",
        "edit_page_title2": "Role Permissions",
        "edit_page_subtitle1": "Menu",
        "edit_page_subtitle2": "Action Allowed",
        "edit_input_1": "User Group",
        "edit_input_2": "Role Name",
        "edit_input_3": "User Management",
        "edit_input_4": "Workflow Management",
        "edit_input_5": "Masters",
        "edit_input_6": "Audit Reports",
        "edit_input_7": "Dashboards",
        "edit_input_8": "Reports",
        "edit_input_9": "Status"
      }
    }
  },
  "configuration": {
    "location": {
      "location": {
        "list": {
          "list_card_title": "Location  Lists",
          "breadcrumbs": "Location",
          "new_user": "Add Location",
          "list_button1": "Add Location ",
          "list_master_search": "Search",
          "list_button2": "Download Location  List",
          "table_fields": {
            "location": {
              "field": "Location  Name",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name"
              }
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true
              }
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true
              }
            },

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true
              }
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "view": {
          "view_page_title": "View Location ",
          "view_page_label_1": "Location  Name",
          "view_category_name": "Category Name",
          "view_location": "Location"
        },
        "add": {
          "add_page_title": "Add Location ",
          "add_page_button": "Add Location ",
          "add_input_1": "Location  Name",
          "add_input_2": "Category Name",
          "add_input_3": "Parent Location"
        },
        "edit": {
          "edit_page_title": "Edit Location ",
          "edit_input_1": "Location  Name",
          "edit_input_2": "Status",
          "edit_category_name": "Category Name",
          "edit_location": "Location"
        }
      },
      "level": {
        "list": {
          "list_card_title": "Location Level Lists",
          "list_button1": "Add Location Level",
          "breadcrumbs": "Level",
          "new_user": "Add Level",
          "list_master_search": "Search",
          "list_button2": "Download Location Level List",
          "table_fields": {
            "level": {
              "field": "Location Level Name",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name"
              }
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true
              }
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true
              }
            },

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true
              }
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "view": {
          "view_page_title": "View Location Level",
          "view_page_label_1": "Location Level Name"
        },
        "add": {
          "add_page_title": "Add Location Level",
          "add_page_button": "Add Location Level",
          "add_input_1": "Location Level Name"
        },
        "edit": {
          "edit_page_title": "Edit Location Level",
          "edit_input_1": "Location Level Name",
          "edit_input_2": "Status"
        }
      },

      "category": {
        "list": {
          "list_card_title": "Location Category Lists",
          "list_button1": "Add Location Category",
          "breadcrumbs":"Location Category",
          "new_user": "Add Category",
          "list_master_search": "Search",
          "list_button2": "Download Location Category List",
          "table_fields": {
            "category": {
              "field": "Location Category Name",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name"
              }
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true
              }
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true
              }
            },

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_maxWidth": 100,
                "column_sortable": true
              }
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "view": {
          "view_page_title": "View Location Category",
          "view_page_label_1": "Location Category Name",
          "view_page_label_2" : "Level Name",
          "view_page_label_3" : "Status"
        },
        "add": {
          "add_page_title": "Add Location Category",
          "add_page_button": "Add Location Category",
          "add_input_1": "Location Category Name",
          "add_input_2": "Level Name"
        },
        "edit": {
          "edit_page_title": "Edit Location Category",
          "edit_input_1": "Location Category Name",
          "edit_input_2": "Status"
        }
      }
    }
  },
  "product_management":{
      "product_type": {
        "list": {
          "list_card_title": "Product Type",
          "list_button1": "Add New Product Type",
          "breadcrumbs": "Product Type",
          "list_master_search": "Search",
          "list_button2": "Download Product Type List",
          "table_fields": {
            "display_name": {
              "field": "Product Type",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
              }
              
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
                }
              }
             
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
            "last_updated_by": {
              "field": "Last Updated By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
                }
              }
            },
            "last_updated_on": {
              "field": "Last Updated On",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
              }
            },
           

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": false,
                "column_search_config": {
                  "search_enabled": false,
                  "search_type": "text",
                  "search_key": "is_active"
                }
              }
             
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "view": {
          "view_page_title": "View Product Type",
          "view_page_label_1": "Product Type",
          "view_page_label_2": "Product Type Description",
          "view_page_label_3": "Status"
        },
        "add": {
          "add_page_title": "Add Product Type",
          "add_page_button": "Add Product Type",
          "add_input_1": "Product Type",
          "add_input_2": "Product Type Description",
          "add_input_3": "Status"
        },
        "edit": {
          "edit_page_title": "Add Product Type",
          "edit_page_button": "Add Product Type",
          "edit_input_1": "Product Type",
          "edit_input_2": "Product Type Description",
          "edit_input_3": "Status"
        }
      },
      "product_category": {
        "list": {
          "list_card_title": "Product Category",
          "breadcrumbs": "Product Category",
          "list_button1": "Add New Category",
          "list_master_search": "Search",
          "list_button2": "Download Product Category List",
          "table_fields": {
            "display_name": {
              "field": "Product Category",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
              }
              
            },
            "product_name": {
              "field": "Product Type",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "product_type__display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product_type__display_name"
              }
              }
              
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
                }
              }
             
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
            "last_updated_by": {
              "field": "Last Updated By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
                }
              }
             
            },

            "last_updated_on": {
              "field": "Last Updated On",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
              }
            },
           
            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": false,
                "column_search_config": {
                  "search_enabled": false,
                  "search_type": "text",
                  "search_key": "is_active"
                }
              }
             
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "ProductType",
              "type": "multidropdown-search"
            },
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "view": {
          "view_page_title": "View Product Category",
          "view_page_label_1": "Product Type",
          "view_page_label_2": "Product Type Description",
          "view_page_label_3": "Status"
        },
        "add": {
          "add_page_title": "Add Product Type",
          "add_page_button": "Add Product Category",
          "add_input_1": "Product Type",
          "add_input_2": "Product Type Description",
          "add_input_3": "Status"
        },
        "edit": {
          "edit_page_title": "Edit Product Type",
          "edit_page_button": "Edit Product Type",
          "edit_input_1": "Product Type",
          "edit_input_2": "Product Type Description",
          "edit_input_3": "Status"
        }
      
      },
      "packaging_type": {
        "list": {
          "list_card_title": "Packaging Type",
          "list_button1": "Add New Packaging Type",
          "breadcrumbs": "Packaging Type",
          "list_master_search": "Search",
          "list_button2": "Download  Packaging Type List",
          "table_fields": {
            "display_name": {
              "field": "Packaging Type",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
              }
              
            },
            "product_name": {
              "field": "Product Type",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
              }
              
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
                }
              }
             
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
            "last_updated_by": {
              "field": "Last Updated By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
                }
              }
             
            },
            "last_updated_on": {
              "field": "Last Updated On",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
           

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": false,
                "column_search_config": {
                  "search_enabled": false,
                  "search_type": "text",
                  "search_key": "is_active"
                }
              }
             
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "ProductType",
              "type": "multidropdown-search"
            },
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "add": {
          "add_page_title": "Add Packaging Type",
          "add_page_button": "Add Packaging Type",
          "add_input_1": "Product Type",
          "add_input_2": "Product Type Description",
          "add_input_3": "Status"
        }
      
      },
      "uom":{
        "list": {
          "list_card_title": "UOM",
          "list_button1": "Add New UOM",
          "breadcrumbs": "UOM",
          "list_master_search": "Search",
          "list_button2": "Download UOM List",
          "table_fields": {
            "full_name": {
              "field": "Volume & UOM",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "full_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "full_name"
              }
              }
              
            },
            "product_name": {
              "field": "Product Type",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key": "display_name",
                "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "display_name"
              }
              }
              
            },
            "created_by": {
              "field": "Created By",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
                }
              }
             
            },
            "created_on": {
              "field": "Created On",
              "column_options": {
                "column_minWidth": 100,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
            "last_updated_by": {
              "field": "Last Updated By",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
                }
              }
             
            },
            "last_updated_on": {
              "field": "Last Updated On",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
              }
            },
           

            "is_active": {
              "field": "Status",
              "column_options": {
                "column_minWidth": 150,
                "column_sortable": false,
                "column_search_config": {
                  "search_enabled": false,
                  "search_type": "text",
                  "search_key": "is_active"
                }
              }
             
            },
            "ACTION": {
              "field": "Action",
              "type": "action",
              "column_options": {
                "column_maxWidth": 150
              }
            }
          },
          "table_top_filter": [
            {
              "status": true,
              "name": "",
              "placeholder": "ProductType",
              "type": "multidropdown-search"
            },
            {
              "status": true,
              "name": "",
              "placeholder": "Status",
              "type": "dropdown-search"
            },
            {
              "status": true,
              "name": "",
              "type": "date",
              "isRange": true,
              "formControlName_start": "start_date",
              "formControlName_end": "end_date"
            }
          ]
        },
        "add": {
          "add_page_title": "Add UOM",
          "add_page_button": "Add UOM"
          
        }
      }
  },

  "scp": {
    "view_register": {
      "scp_card_title": "Self Registration Requests",
      "scp_list": "SCP List",
      "scp_onboard_card_title": "Onboard Process Requests",
     
      "breadcrumbs": "SCP Participants",
      "title": "View details",
      "onboard_title": "View details",
      "approver_title": "Registration Approval - Application -",
      "onboard_approver_title": "Onboarding Approval - Application -",
      "self_reg": "Self Registration Requests ",
      "onboard_self_reg": "Onboard Process Requests ",
      "application": "Application",
      "edit_title": "Edit details",
      "edit": "Edit",
      "view": "View",
      "address": "Company Address",
      "lic_type": "License Type",
      "date_of_incorporation": "Date of Incorporation",
      "company_ownership": "Company Ownership",
      "tin_number": "TIN Number"
    },
    "application_status": {
      "welcome_note": "Welcome to Digital Tax Stamp Management System!",
      "title": "Application Status",
      "req_id": "Request ID",
      "lic_type": "License Type",
      "company_name": "Company Name",
      "submission_date": "Submission Date",
      "approval_status": "Approval Status",
      "onboarding_status": "Onboarding Status",
      "view_details": "View Details",
      "cmt": "Comments has been received for your registration approval request!",
      "view_cmt": "View Comments"
    },
    "register": {
      "list": {
        "list_card_title": "Scp Register List",
        "list_master_search": "Search",
        "list_button1": "Create Scp Register",
        "list_button2": "Download Scp Register List",
        "scp_list_table_fields": {
          "company_name": {
            "field": "Company",
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__company__name"
              }
            }
          },
          "tin": {
            "field": "TIN",
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"company__tin_number",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__tin_number"
              }
            }
          },
          "license_type": {
            "field": "License Type",
            "column_options": {
              "column_minWidth": 120,
              "column_sorting_key":"company__company__license_type__display_name",
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__company__license_type__display_name"
              }
            }
          },
          "business_site": {
            "field": "Business Site",
            "column_options": {
              "show_popover":true,
              "popover_template_name":"business_site_template",
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"company__company_sites__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__company_sites__name"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "last_updated_by": {
            "field": "Last Updated By",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
           
          },
          "last_updated_on": {
            "field": "Last Updated On",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"last_updated_on_before",
                "search_key_end": "last_updated_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "onboarding_table_fields": {
          "registration_code": {
            "field": "Registration code",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"code",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "code"
              }
            }
          },
          "company_name": {
            "field": "Company Name",
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__company__name"
              }
            }
          },
          "license_type": {
            "field": "License Name",
            "column_options": {
              "column_minWidth": 170,
              "column_sorting_key":"company__company__license_type__display_name",
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__company__license_type__display_name"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
            }
          },
          "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "last_updated_by": {
            "field": "Last updated by",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last updated on",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
            }
          },

          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "table_fields": {
          "registration_code": {
            "field": "Registration code",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"registration_code",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "registration_code"
              }
            }
          },
          "company_name": {
            "field": "Company Name",
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__name"
              }
            }
          },
          "license_type": {
            "field": "License Name",
            "column_options": {
              "column_minWidth": 170,
              "column_sorting_key":"company__license_type__display_name",
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "company__license_type__display_name"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "last_updated_by": {
            "field": "Last updated by",
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last updated on",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
                }
            }
          },
          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "scp_list_table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Company",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "License Type",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ],
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "License Type",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
      },
      "view_edit": {
        "title": "Self Registration",
        "business_detail_title": "TIN Validation",
        "business_id_note": "Validating your TIN is essential for self-registration.",
        "business_id": "Tax Identification Number",
        "business_notes": "Validating your TIN is essential for self-registration",
        "proceed_lbl": "Proceed",
        "otp": "Validate OTP",
        "verify_otp": "Verify OTP",
        "regn_success_msg": "Your self registration request is submitted. Check your email for login credentials to track your status & more.",
        "regn_update_success_msg": "The changes to the registration have been saved successfully."
      }
    },
    "onboard": {
      "title": "Onboarding Process - Site Info",
      "site_notes_1": "What to have in business site information?",
      "site_notes_2": "Provide details about your business, including information related to your site, production (if applicable), and the products you offer.",
      "bank_site_note_1": "What to have in bank information?",
      "bank_site_note_2": "Provide details about your banking, including information related to your  bank name,branch,account name,etc.",
      "supply_site_note_1": "What to have in Supply Chain Information?",
      "supply_site_note_2": "Provide details about your Supply Chain, including information related to your  Supplier Company Name,Supplier Email ID,License Type,etc.",
      "user_site_note_1": "What to have in business user information?",
      "user_site_note_2":"Provide details about your Users, including information related to your  Name,Email Id,Contact Number,etc.",
      "other_site_note_1": "What to have in Other Information?",
      "other_site_note_2": "Provide details about your Attachment",
      "sub_heading_1": "Business Site Information",
      "sub_heading_2": "Bank Information",
      "sub_heading_3": "Supply Chain Information",
      "sub_heading_4": "Users Information",
      "sub_heading_5": "Other Information"
    }
  },
  "dashboard": {},
  "reports": {},
  "audit_log":{
        "title":"Audit log",
        "table_fields": {
            "serial_no": {
              "field": "S.No",
              "column_options": {
                "column_sortable": false,
                "column_maxWidth": 50
              }
            },
            "user": {
              "field": "User",
              "view_Action": true,
              "column_options": {
                "column_minWidth": 120,
                "column_sortable": true,
                "column_sorting_key":"user",
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "user"
                }
              }
            },
            "action": {
              "field": "Action",
              "column_options": {
                "column_minWidth": 120,
                "column_sortable": true,
                "column_sorting_key":"action",
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "action"
                }
              }
            },
            "msg": {
              "field": "Message",
              "column_options": {
                "column_minWidth": 200,
                "column_sortable": true,
                "column_sorting_key":"msg",
                "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "msg"
                }
              }
            },
            "client_ip": {
                "field": "IP Address / IMEI Number",
                "column_options": {
                    "column_minWidth": 100,
                    "column_sortable": true,
                    "column_sorting_key":"client_ip",
                    "column_search_config": {
                      "search_enabled": true,
                      "search_type": "text",
                      "search_key": "client_ip"
                    }
                }
            },
            "create_datetime": {
                "field": "Created On",
                "column_options": {
                    "column_minWidth": 100,
                    "column_sortable": true,
                    "column_sorting_key":"created_on",
                  "column_search_config": {
                    "search_enabled": true,
                    "search_type": "date-range",
                    "search_key_start" :"start_date",
                    "search_key_end": "end_date",  
                    "date_range_start": "",                   
                    "date_range_end": ""   
                  }
                }
            }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
    },

  "workflow_audit_log":{
      "title" : "Audit Logs",
      "view_title": "View Detail",
      "table_fields":{
        "user": {
          "field": "User"
        },
        "role": {
          "field": "Role"
        },
        "comment": {
          "field": "Comment"
        },
        "status": {
          "field": "Status"   
        },
        "raised_on": {
          "field": "Date"
        },
        "ACTION": {
          "field": "Attachment",
          "type": "action"
        
        }  
    }
  },

  "workflow": {
    "create": {
      "add": "Add State",
      "content_type": "Content Type",
      "valuation_type": "Valuation Purpose/Type",
      "transition": "Transition",
      "entity": "User Group",
      "group": "Role",
      "select_entity": "Select User Group",
      "select_group": "Select Role",
      "description": "Action Text",
      "update": "Assign Role",
      "create": "Create Workflow",
      "save": "Save",
      "title": "Workflow",
      "select_warnning_msg": "Select atleast one",
      "select_single_warnning_msg": "Select any one transition",
      "delete_confirmation": "Do you want to delete this Workflow States item",
      "delete": "Delete",
      "transitionDetails": "Assign Role",
      "table_fields": {
        "content_type": {
          "field": "Content Type",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "valuation_type": {
          "field": "Valuation Purpose/Type",
          "column_options": {
            "column_minWidth": 150,
            "column_sortable": true
          }
        },
        "transition": {
          "field": "Transition Direction",
          "column_options": {
            "column_minWidth": 170,
            "column_sortable": true
          }
        },
        "group": {
          "field": "Group",
          "column_options": {
            "column_minWidth": 170,
            "column_sortable": true
          }
        },
        "created_by": {
          "field": "Created By",
          "column_options": {
            "column_minWidth": 150,
            "column_sortable": false
          }
        },
        "created_on": {
          "field": "Created On",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false
          }
        },
        "last_modified_on": {
          "field": "Last modified on",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false,
            "column_sorting_key": "region__name"
          }
        },
        "ACTION": {
          "field": "Action",
          "type": "action",
          "column_options": {
            "column_maxWidth": 70
          }
        }
      },
      "table_top_filter": [
        {
          "status": true,
          "name": "Content Type",
          "placeholder": "Select Content Type",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "Date Range",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    },
    "Transition": {
      "update": "Update Transition",
      "create": "Create Transition",
      "title": "Transitions",
      "source_state": "Source State",
      "next_state": "Next State",
      "transition_direction": "Transition Direction",
      "table_fields": {
        "source_state": {
          "field": "Source State",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "next_state": {
          "field": "Next State",
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "transition_direction": {
          "field": "Transition Direction",
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "created_by": {
          "field": "Created By",
          "column_options": {
            "column_minWidth": 150,
            "column_sortable": false
          }
        },
        "created_on": {
          "field": "Created On",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false
          }
        },
        "last_modified_on": {
          "field": "Last modified on",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false,
            "column_sorting_key": "region__name"
          }
        },
        "ACTION": {
          "field": "Action",
          "type": "action",
          "column_options": {
            "column_maxWidth": 70
          }
        }
      },
      "table_top_filter": [
        {
          "status": true,
          "name": "Source State",
          "placeholder": "Select Source State",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "Next State",
          "placeholder": "Select Next_State",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "Date Range",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    },
    "states": {
      "update": "Update State",
      "create": "Add State",
      "slug": "Slug",
      "label": "Label",
      "action_complete_label": "Action Completed Label",
      "description": "Description",
      "bgColor": "Background Color",
      "title": "States",
      "table_fields": {
        "slug_name": {
          "field": "Slug name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "label": {
          "field": "Label",
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true
          }
        },
        "action_complete_label": {
          "field": "Action Completed Label",
          "column_options": {
            "column_minWidth": 200,
            "column_sortable": true
          }
        },
        "created_by": {
          "field": "Created By",
          "column_options": {
            "column_minWidth": 150,
            "column_sortable": false
          }
        },
        "created_on": {
          "field": "Created On",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false
          }
        },
        "last_modified_on": {
          "field": "Last modified on",
          "column_options": {
            "column_minWidth": 100,
            "column_sortable": false,
            "column_sorting_key": "region__name"
          }
        },
        "ACTION": {
          "field": "Action",
          "type": "action",
          "column_options": {
            "column_maxWidth": 70
          }
        }
      },
      "table_top_filter": [
        {
          "status": true,
          "name": "Date Range",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    }
  },
  "comments": {
    "title": "Comments",
    "Add": "Add Comments",
    "add_btn": "Submit"
  },
  "tax_management" : {
    "stamp" : {
      "title":"Tax Stamp",
      "add":"Add Tax Stamp",
      "edit":"Edit Tax Stamp",
      "table_fields": {
          "tax_stamp": {
            "field": "Tax Stamp",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "name"
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key":"created_by",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified on",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"is_active",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
    },
    "slab" : {
      "title":"Tax Slab",
      "add":"Add Tax Slab",
      "edit":"Edit Tax Slab",
      "table_fields": {
          "product_type": {
            "field": "Product Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product_type_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product_type_name"
              }
            }
          },
          "product_category": {
            "field": "Product Category",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product_category_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product_category_name"
              }
            }
          },
          "stamp_name": {
            "field": "Stamp Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"tax_stamp_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "tax_stamp_name"
              }
            }
          },
          "stamp_rate": {
            "field": "Stamp Rate",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"stamp_rate",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "stamp_rate"
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key":"created_by",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified On",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Product Type",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Product Category",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
    }
  },
  "scp_product": {
    "product_list":{
      "add":"Add Product",
      "edit":"Edit Product",
      "view_title": "Product Detail",
      "update_status":"Update Status",
      "title":"Product List",
      "table_fields": {
        "product_name": {
          "field": "Product Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 180,
            "column_sortable": true,
            "column_sorting_key":"product__display_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__display_name"
            }
          }
        },
        "brand_name": {
          "field": "Brand Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 120,
            "column_sortable": true,
            "column_sorting_key":"product__brand_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__brand_name"
            }
          }
        },
          "product_type": {
            "field": "Product Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__product_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__product_type__display_name"
              }
            }
          },
          "product_category": {
            "field": "Product Category",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__display_name"
              }
            }
          },
          "company_name": {
            "field": "Company Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__company__company__name"
              }
            }
          },
          "site": {
            "field": "Site",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__name"
              }
            }
          },
          "license_type": {
            "field": "License Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__license_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__company__company__license_type__display_name"
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"created_by",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified On",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "approval_status": {
            "field": "Approval Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "is_active": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"is_active",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
      "table_top_filter": [
        {
          "status": true,
          "name": "",
          "placeholder": "Product Type",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Product Category",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Company Name",
          "type": "multidropdown-search"
        },
       
        {
          "status": true,
          "name": "",
          "placeholder": "Site",
          "type": "multidropdown-search"
        },
       
        {
          "status": true,
          "name": "",
          "placeholder": "Status",
          "type": "dropdown-search"
        },
        {
          "status": true,
          "name": "",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    },
    "scp_list":{
      "table_fields": {
        "product_name": {
          "field": "Product Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 180,
            "column_sortable": true,
            "column_sorting_key":"product__display_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__display_name"
            }
          }
        },
        "brand_name": {
          "field": "Brand Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 180,
            "column_sortable": true,
            "column_sorting_key":"product__brand_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__brand_name"
            }
          }
        },
          "product_type": {
            "field": "Product Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__product_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__product_type__display_name"
              }
            }
          },
          "product_category": {
            "field": "Product Category",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__display_name"
              }
            }
          },
          "site": {
            "field": "Site",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__name"
              }
            }
          },
          "license_type": {
            "field": "License Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__license_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__company__company__license_type__display_name"
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"created_by",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified On",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "approval_status": {
            "field": "Approval Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "is_active": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"is_active",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
      "table_top_filter": [
        {
          "status": true,
          "name": "",
          "placeholder": "Product Type",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Product Category",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Site",
          "type": "multidropdown-search"
        },
       
        {
          "status": true,
          "name": "",
          "placeholder": "Status",
          "type": "dropdown-search"
        },
        {
          "status": true,
          "name": "",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    },
    "revenue_list":{
      "table_fields": {
        "product_name": {
          "field": "Product Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 180,
            "column_sortable": true,
            "column_sorting_key":"product__display_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__display_name"
            }
          }
        },
        "brand_name": {
          "field": "Brand Name",
          "view_Action": true,
          "column_options": {
            "column_minWidth": 180,
            "column_sortable": true,
            "column_sorting_key":"product__brand_name",
            "column_search_config": {
              "search_enabled": true,
              "search_type": "text",
              "search_key": "product__brand_name"
            }
          }
        },
          "product_type": {
            "field": "Product Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__product_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__product_type__display_name"
              }
            }
          },
          "product_category": {
            "field": "Product Category",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"product__category__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product__category__display_name"
              }
            }
          },
          "license_type": {
            "field": "License Type",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"site__company__company__license_type__display_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "site__company__company__license_type__display_name"
              }
            }
          },
          "created_by": {
            "field": "Created by",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"created_by",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified On",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "approval_status": {
            "field": "Approval Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "is_active": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": false,
              "column_sorting_key":"is_active",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "is_active"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
      "table_top_filter": [
        {
          "status": true,
          "name": "",
          "placeholder": "Product Type",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Product Category",
          "type": "multidropdown-search"
        },
        {
          "status": true,
          "name": "",
          "placeholder": "Status",
          "type": "dropdown-search"
        },
        {
          "status": true,
          "name": "",
          "type": "date",
          "isRange": true,
          "formControlName_start": "start_date",
          "formControlName_end": "end_date"
        }
      ]
    }
  },
  "grievance_management":{
    "title":"Grievance",
    "approver_title":"Grievance Approval - Application - ",
    "self_reg":"Grievance Requests",
    "application":"Application",
      "table_fields": {
          "req_id": {
            "field": "Request ID",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"code",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "code"
              }
            }
          },
          "nature_of_grievance": {
            "field": "Nature of Grievance",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 220,
              "column_sortable": true,
              "column_sorting_key":"grievance_type__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "grievance_type_name"
              }
            }
          },
          "raised_on": {
            "field": "Raised on",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"created_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"created_on_before",
                  "search_key_end": "created_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "raised_by": {
            "field": "Raised by",
            "column_options": {
              "column_minWidth": 110,
              "column_sorting_key":"grievance_user__name",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "grievance_user__name"
              }
            }
          },
          "contact_number": {
            "field": "Contact Number",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"phone_number",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "phone_number"
              }
            }
          },
          "email_id": {
            "field": "Email ID",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": false,
              "column_sorting_key":"name",
              "column_search_config": {
                "search_enabled": false,
                "search_type": "text",
                "search_key": "name"
              }
            }
          },
          "product_name": {
            "field": "Product Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 180,
              "column_sortable": true,
              "column_sorting_key":"product__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "product_name"
              }
            }
          },
          "manufacturer_supplier_name": {
            "field": "Manufacturer/Supplier Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 250,
              "column_sortable": true,
              "column_sorting_key":"manufacturer__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "manufacturer"
              }
            }
          },
          "store_name": {
            "field": "Store Name",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 200,
              "column_sortable": true,
              "column_sorting_key":"store_name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "store_name"
              }
            }
          },  
          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "status__action_complete_label"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Nature of Grievance ",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
  },
  "forecast_management":{
    "title" : "Forecast",
    "add" : "Add Forecast",
    "new" : "New Forecast",
    "edit": "Edit Forecast",
    "view_title": "Forecast Detail",
    "table_fields": {
          "req_id": {
            "field": "Request ID",
            "view_Action": true,
            "column_options": {
              "column_minWidth": 120,
              "column_sortable": true,
              "column_sorting_key":"code",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "code"
              }
            }
          },
          "company": {
            "field": "Company",
            "column_options": {
              "column_minWidth": 220,
              "column_sortable": true,
              "column_sorting_key":"forecast__site__company__company__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "forecast__site__company__company__name"
              }
            }
          },
          "business_site": {
            "field": "Business Site",
            "column_options": {
              "column_minWidth": 220,
              "column_sortable": true,
              "column_sorting_key":"forecast__site__name",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "forecast__site__name"
              }
            }
          },
          "year": {
            "field": "Year",
            "column_options": {
              "column_minWidth": 110,
              "column_sorting_key":"forecast__year__display_name",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "forecast__year__display_name"
              }
            }
          },
          "brand_name": {
            "field": "Brand Name",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"product__brand_name",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "product__brand_name"
              }
            }
          },
          "product_name": {
            "field": "Product Name",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"product__display_name",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "product__display_name"
              }
            }
          },
          "stamp_quantity": {
            "field": "Stamp Quantity",
            "column_options": {
              "column_minWidth": 190,
              "column_sorting_key":"total_stamp",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "total_stamp"
              }
            }
          },
           "created_by": {
            "field": "Created By",
            "column_options": {
              "column_minWidth": 150,
              "column_sortable": true,
              "column_sorting_key" : "created_by",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "created_by"
              }
            }
          },
          "created_on": {
            "field": "Created On",
            "column_options": {
              "column_minWidth": 100,
              "column_sortable": true,
              "column_sorting_key": "created_on",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "date-range",
                "search_key_start" :"created_on_before",
                "search_key_end": "created_on_after",  
                 "date_range_start": "",                   
                "date_range_end": ""   
              }
            }
          },
          "last_updated_by": {
            "field": "Last Modified by",
            "column_options": {
              "column_minWidth": 180,
              "column_sorting_key":"last_updated_by",
              "column_sortable": true,
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "text",
                  "search_key": "last_updated_by"
              }
            }
          },
          "last_updated_on": {
            "field": "Last Modified On",
            "column_options": {
              "column_minWidth": 110,
              "column_sortable": true,
              "column_sorting_key":"last_updated_on",
              "column_search_config": {
                  "search_enabled": true,
                  "search_type": "date-range",
                  "search_key_start" :"last_updated_on_before",
                  "search_key_end": "last_updated_on_after",  
                   "date_range_start": "",                   
                  "date_range_end": ""   
              }
            }
          },
          "status": {
            "field": "Status",
            "column_options": {
              "column_minWidth": 70,
              "column_sortable": true,
              "column_sorting_key":"forecast_product_status__status__action_complete_label",
              "column_search_config": {
                "search_enabled": true,
                "search_type": "text",
                "search_key": "forecast_product_status__status__action_complete_label"
              }
            }
          },
          "ACTION": {
            "field": "Action",
            "type": "action",
            "column_options": {
              "column_maxWidth": 70
            }
          }
        },
        "table_top_filter": [
          {
            "status": true,
            "name": "",
            "placeholder": "Company",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Business Site",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "placeholder": "Status",
            "type": "dropdown-search"
          },
          {
            "status": true,
            "name": "",
            "type": "date",
            "isRange": true,
            "formControlName_start": "start_date",
            "formControlName_end": "end_date"
          }
        ]
  },
  "order_management":{
    "title" : "Orders",
    "add" : "Create Order",
    "new" : "Create New Order",
    "edit": "Edit Order",
    "view_title": "Order Detail",
    "place_order": "Place Your Order",
    "view_invoice":{
      "invoice_title":"View Invoice",
      "invoice_subtitle":"Orders / Order Detail - 232344 "
    }
  }
}
