import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updateAttachementFileUrl'
})
export class UpdateAttachementFileUrlPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      let result = value.replace('/media', '/api/media');
      return result;
    }
    return value;
  }

}
