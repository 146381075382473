import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { colorCodes } from '../utils/constants';

@Injectable({ providedIn: 'root' })
export class SnackbarService {

  constructor(public snackBar: MatSnackBar) { }

  public message: string | any;
  public actionLabel: string | any;
  public autoHide: number = 3500;
  public setAutoHide: boolean = false;
  public horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';
  className: string = '';

  loadSnackBar(message: any, colorCodeValue: colorCodes, actionLabel?: string) {
    this.message = message;
    this.actionLabel = actionLabel;
    this.getColorCodes(colorCodeValue);
    this.openSnackBar();
  }

  getColorCodes(colorCodeValue: colorCodes) {
    switch (colorCodeValue) {
      
      case colorCodes.SUCCESS:
        this.className = "bg-success"
        break;
      case colorCodes.WARNING:
        this.className = "bg-warning"
        break;
      case colorCodes.ERROR:
        this.className = "bg-danger"
        break;
      case colorCodes.INFO:
        this.className = "bg-info"
        break;
      default:
        this.className = ""
        break;
    }
  }


  openSnackBar() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.panelClass = this.className ? [this.className, 'text-white'] : undefined;
    config.duration = this.autoHide;
    this.snackBar.open(
      this.message,
      this.actionLabel ? this.actionLabel : 'close',
      config
    );
  }

}
