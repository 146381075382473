import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common/common.service';
import { PagePermssions } from 'src/app/core/services/utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-file-preview',
  standalone: false,
  templateUrl: './file-preview.component.html',
  styleUrl: './file-preview.component.scss'
})
export class FilePreviewComponent {
  permission: any = PagePermssions
  showWithoutImg: boolean = false;
  spinnerHide: boolean = false;
  downloadUrl: any;
  name!: string;
  currentFileIndex: number = 0;
  staticText: any = (textConfiguration as any).default;
  attachments: any;
  totalAttachments!: number;
  currentAttachmentIndex!: number;
  constructor(
    private _common: CommonService,
    public _filePreview: MatDialogRef<FilePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public _filePreviewDialogData: any) {
  }

  ngOnInit(): void {

    this.attachments = this._filePreviewDialogData?.attachments || [];
    this.currentAttachmentIndex = 0; // Start with the first attachment
    this.totalAttachments = this.attachments.length;
    this.loadAttachment(this.currentAttachmentIndex);


  }

  previewInNewWindow(){
     // Open the file in a new window
     window.open(this.downloadUrl, '_blank');
  }
  loadAttachment(index: number) {
    this.currentAttachmentIndex = index;
    this.downloadUrl = this.attachments[index];
    let filedata = this.attachments?.url?.split("/") || this.downloadUrl;
    //filedata = filedata?.[filedata?.length - 1];
    const name = filedata?.split("?")?.[0];
    this.name = name;

    if (!filedata?.includes("png") && !filedata.includes("jpg") && !filedata?.includes("jpeg")) {
      this.showWithoutImg = true;
    } else {
      this.showWithoutImg = false;
    }
  }


  dialogClose() {
    this._filePreview.close();
  }
  onIframeLoaded() {
    this.spinnerHide = true;
  }

  downloadFile() {
    this._common.downloadFile(this._filePreviewDialogData?.url, this.staticText?.common?.report);
  }
  previousPage() {
    if (this.currentAttachmentIndex > 0) {
      this.loadAttachment(this.currentAttachmentIndex - 1);
    }
  }

  nextPage() {
    if (this.currentAttachmentIndex < this.totalAttachments - 1) {
      this.loadAttachment(this.currentAttachmentIndex + 1);
    }
  }
}
