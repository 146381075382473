import { DatePipe, LowerCasePipe, TitleCasePipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { NgxPermissionsService } from 'ngx-permissions'
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service'
import { StorageService } from '../storage/storage.service'
import { ApiMethod, FORMAT, MENU_UNIQ_NAME, ONLY_NUMBERS_REGEX, StateManagement, USERMANAGEMENT, USERTYPE } from '../utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json'
import { routePath, PagePermssions } from 'src/app/core/services/utils/constants';
import { ActivatedRoute } from '@angular/router'
import { FormArray } from '@angular/forms'
import * as XLSX from 'xlsx';
import moment from 'moment';


// @ts-ignore
/* import html2pdf from 'html2pdf.js'; */
import { LodashService } from '../lodash/lodash.service'
import { Store } from '@ngrx/store';
import { setMasterAPIProgressStatus, setMasterList } from 'src/app/state/master/master.action'
import { AppState } from 'src/app/state/master/master.state'
import { MatDialog } from '@angular/material/dialog'
import { LoaderService } from '../loader/loader.service'
import { FilePreviewComponent } from 'src/app/shared/components/modal/file-preview/file-preview.component'
import { RouterService } from '../router/router.service'
import { Observable, takeUntil, map, Subject } from 'rxjs'
import { HttpService } from '../http/http.service'
// import { FilePreviewComponent } from 'src/app/shared/components/file-preview/file-preview.component'

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  date = new Date()
  staticText: any = (textConfiguration as any).default;

  userMangementTab: any = [
    {
      "title" : "User Group",
      "route" : routePath.USER_TYPE_DEFAULT_LIST_PATH,
      "mode" : USERMANAGEMENT.ENTITY,
      "permission" : [PagePermssions.VIEW_ENTITY]
    },
    {
      "title" : "Roles",
      "route" : routePath.ROLE_DEFAULT_LIST_PATH,
      "mode" : USERMANAGEMENT.ROLE,
      "permission" : [PagePermssions.VIEW_CUSTOMGROUP]
    },
    {
      "title" : "Users",
      "route" : routePath.USER_DEFAULT_LIST_PATH,
      "mode" : USERMANAGEMENT.USER,
      "permission" : [PagePermssions.VIEW_CUSTOMUSER]
    }
  ]

  routePrefix: string = '';

  sideNavMenu: Array<any> = [
    {
      uniqId: this.generateUUID(),
      uniq_name: MENU_UNIQ_NAME?.USERS,
      name: 'User',
      icon: 'user',
      router: routePath?.USER_PATH,
      permission: [
        PagePermssions.VIEW_CUSTOMUSER,
        PagePermssions.VIEW_CUSTOMGROUP,
        PagePermssions.VIEW_ENTITY
      ],
      children: []
    },
    {
      uniqId: this.generateUUID(),
      uniq_name: MENU_UNIQ_NAME?.LOCATION,
      name: 'Location',
      icon: 'location',
      router: null,
      permission: [PagePermssions.VIEW_LOCATION_LEVEL, PagePermssions.VIEW_LOCATION_CATEGORY, PagePermssions.VIEW_LOCATION_MENU, PagePermssions.VIEW_LOCATION_MASTER],//'PagePermssions.VIEW_LOCATION_MASTER'
      children: [
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.LEVEL_LIST,
          name: 'Level',
          icon: null,
          router: routePath?.LEVEL_LIST_PATH,
          permission: [PagePermssions.VIEW_LOCATION_LEVEL],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.CATEGORY_LIST,
          name: "Category",
          icon: null,
          router: routePath?.CATEGORY_LIST_PATH,
          permission: [PagePermssions.VIEW_LOCATION_CATEGORY],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.LOCATION_LIST,
          name: "Location",
          icon: null,
          router: routePath?.LOCATION_PATH,
          permission: [PagePermssions.VIEW_LOCATION_MENU],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
      ]
    },
    {
      uniqId: this.generateUUID(),
      uniq_name:MENU_UNIQ_NAME?.SCP,
      name: 'SCP',
      icon: 'user',
      router:null,
      permission: [PagePermssions.VIEW_SCP, PagePermssions.VIEW_ONBOARD],
      children:[
        {
          uniqId: this.generateUUID(),
          uniq_name:MENU_UNIQ_NAME?.REGISTRATION_SCP,
          name: 'Registration Request',
          icon: null,
          router: routePath?.SCP_PATH,
          permission: [PagePermssions.VIEW_SCP],
          children:[]
        },
        {
          uniqId: this.generateUUID(),
          uniq_name:MENU_UNIQ_NAME?.ONBOARD_SCP,
          name: 'Onboarding Request',
          icon: null,
          router: routePath?.ONBOARD_SCP_PATH,
          permission: [PagePermssions.VIEW_ONBOARD],
          children:[]
        },
        {
          uniqId: this.generateUUID(),
          uniq_name:MENU_UNIQ_NAME?.SCP_LIST,
          name: 'SCP List',
          icon: null,
          router: routePath?.SCP_LIST,
          permission: [PagePermssions.VIEW_ONBOARD],
          children:[]
        }
      ]
    },
    {
      uniqId: this.generateUUID(),
      uniq_name:MENU_UNIQ_NAME?.PRODUCT,
      name: 'Product',
      icon: 'package',
      router:null,
      permission: [PagePermssions.VIEW_PRODUCT_TYPE, PagePermssions.VIEW_PRODUCT_CATEGORY, PagePermssions.VIEW_PRODUCT_UOM, PagePermssions.VIEW_PRODUCT_PACKAGE,PagePermssions.VIEW_SCP_PRODUCT],
      children:[
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.PRODUCT_TYPE_LIST,
          name: 'Product Type',
          icon: null,
          router: routePath?.PRODUCT_TYPE_LIST_PATH,
          permission: [PagePermssions.VIEW_PRODUCT_TYPE],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.PRODUCT_CATEGORY,
          name: 'Product Category',
          icon: null,
          router: routePath?.PRODUCT_CATEGORY_LIST_PATH,
          permission: [PagePermssions.VIEW_PRODUCT_CATEGORY],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.PRODUCT_UOM,
          name: 'UOM',
          icon: null,
          router: routePath?.PRODUCT_UOM_LIST_PATH,
          permission: [PagePermssions.VIEW_PRODUCT_UOM],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.PRODUCT_PACKAGE,
          name: 'Packaging Type',
          icon: null,
          router: routePath?.PRODUCT_PACKAGE_LIST_PATH,
          permission: [PagePermssions.VIEW_PRODUCT_PACKAGE],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.PRODUCT_SCP,
          name: 'SCP Product',
          icon: null,
          router: routePath?.PRODUCT_SCP_LIST_PATH,
          permission: [PagePermssions.VIEW_SCP_PRODUCT],//PagePermssions.VIEW_LOCATION_MASTER
          children: []
        },
      ]
    },
    {
      uniqId: this.generateUUID(),
      uniq_name:MENU_UNIQ_NAME?.TAX_MANAGEMENT,
      name: 'Tax',
      icon: 'tax',
      router:null,
      permission: [PagePermssions.VIEW_TAX_STAMP, PagePermssions.VIEW_TAX_SLAB],
      children:[
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.STAMP_LIST,
          name: 'Tax stamp',
          icon: null,
          router: routePath?.TAX_STAMP_LIST,
          permission: [PagePermssions.VIEW_TAX_STAMP],
          children: []
        },
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.SLAB_LIST,
          name: 'Tax Slab',
          icon: null,
          router: routePath?.TAX_SLAB_LIST,
          permission: [PagePermssions.VIEW_TAX_SLAB],
          children: []
        }
      ]
    },
    {
      uniqId: this.generateUUID(),
      uniq_name: MENU_UNIQ_NAME?.ORDER_MANAGEMENT,
      name: 'order',
      icon: 'grievance',
      router: routePath?.ORDER_LIST,
      permission: [""],
      children: []
    },
    {
      uniqId: this.generateUUID(),
      uniq_name: MENU_UNIQ_NAME?.GRIEVANCE_MANAGEMENT,
      name: 'grievance',
      icon: 'grievance',
      router: routePath?.GRIEVANCE_SLAB_LIST,
      permission: [
        PagePermssions.VIEW_GRIEVANCE
      ],
      children: []
    },
    {
      uniqId: this.generateUUID(),
      uniq_name: MENU_UNIQ_NAME?.FORECAST,
      name: 'Forecast',
      icon: 'forecast',
      router: routePath?.FORECAST_LIST,
      permission: [ PagePermssions.VIEW_FORECAST ],
      children: []
    },
    {
      uniqId: this.generateUUID(),
      uniq_name:MENU_UNIQ_NAME?.REPORTS,
      name: 'Reports',
      icon: 'reports',
      router:null,
      permission: [PagePermssions.VIEW_AUDIT],
      children:[
        {
          uniqId: this.generateUUID(),
          uniq_name: MENU_UNIQ_NAME?.AUDIT_LOG,
          name: 'Audit log',
          icon: null,
          router: routePath?.AUDIT_LOG,
          permission: [PagePermssions.VIEW_AUDIT],
          children: []
        }]
    }
  ];
  

  private destroy$ = new Subject<void>();
  constructor(
    private datePipe: DatePipe,
    private lowerCasePipe: LowerCasePipe,
    private _storage: StorageService,
    private _dailog: MatDialog,
    private _loader: LoaderService,
    public _routeService: RouterService,
    //private asynchronousPipe: AsyncPipe,
    private TitleCasePipe: TitleCasePipe,
    private _customTable: CustomTableService,
    private _lodash: LodashService,
    private permissionsService: NgxPermissionsService,
    private activated_route: ActivatedRoute,
    private _http: HttpService,
    private store: Store<{ master: AppState }>
  ) { }

  supplyChainParticipant(isSupplyChainParticipant:boolean){
    this.sideNavMenu?.forEach((element:any) => {
      if (element?.uniq_name == MENU_UNIQ_NAME?.SCP) {
        if (isSupplyChainParticipant) {
          element.router = routePath?.SCP_APPLICATION_PATH;
          element.children = [];
        }else{
          element.router = null;
          element.children = [
            {
              uniqId: this.generateUUID(),
              uniq_name:MENU_UNIQ_NAME?.REGISTRATION_SCP,
              name: 'Registration Request',
              icon: null,
              router: routePath?.SCP_PATH,
              permission: [PagePermssions.VIEW_SCP],
              children:[]
            },
            {
              uniqId: this.generateUUID(),
              uniq_name:MENU_UNIQ_NAME?.ONBOARD_SCP,
              name: 'Onboarding Request',
              icon: null,
              router: routePath?.ONBOARD_SCP_PATH,
              permission: [PagePermssions.VIEW_ONBOARD],
              children:[]
            },
            {
              uniqId: this.generateUUID(),
              uniq_name:MENU_UNIQ_NAME?.SCP_LIST,
              name: 'SCP List',
              icon: null,
              router: routePath?.SCP_LIST,
              permission: [PagePermssions.VIEW_ONBOARD],
              children:[]
            }
          ];
        }
      }
    });
  }

  navigatePages(navigateTo:string){
    this._routeService.navigatePages(`${navigateTo}`);
  }

  /* generateUUIDOlD() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = Math.floor(Math.random() * 16),
          v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  } */

  generateUUID() {
    const array = new Uint8Array(16);
    crypto.getRandomValues(array);
  
    // Format the UUID according to the RFC 4122 standard
    array[6] = (array[6] & 0x0f) | 0x40; // Version 4
    array[8] = (array[8] & 0x3f) | 0x80; // Variant 10
  
    return [...array]
      .map((b, i) =>
        [4, 6, 8, 10].includes(i) ? `-${b.toString(16).padStart(2, '0')}` : b.toString(16).padStart(2, '0')
      )
      .join('');
  }
  

  transformDate(value: any, dateFormat: any = FORMAT.DATE) {
    return this.datePipe?.transform(value, dateFormat)
  }

  titleCase(text: any) {
    return this.TitleCasePipe.transform(text)
  }

  lowerCase(text: any): string {
    return this.lowerCasePipe.transform(text)
  }

  downloadTemplate(customExcelHeaders: any, name: string) {
    const templateToExcel: string[][] = [customExcelHeaders, []];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${name}-Template.xlsx`);
  }

  sortData(sortData: any, sortKey: any) {
    sortData.sort((dataA: any, dataB: any) => {
      if (dataA[sortKey] === dataB[sortKey]) {
        return 0
      } else if (dataA[sortKey] === null) {
        return 1
      } else if (dataB[sortKey] === null) {
        return -1
      } else if (dataA[sortKey] < dataB[sortKey]) {
        return -1
      } else {
        return 1
      }
    })
    return sortData
  }

  customYearPicker(operation: any, yearValue?: any) {
    if (operation === '+') {
      return new Date(
        this.date.getFullYear() + yearValue,
        this.date.getMonth(),
        this.date.getDate()
      )
    } else if (operation === '-') {
      return new Date(
        this.date.getFullYear() - yearValue,
        this.date.getMonth(),
        this.date.getDate()
      )
    }
    return this.date
  }

  acceptNumberOnlyEvent = (event: KeyboardEvent) => {
    const pattern = ONLY_NUMBERS_REGEX
    const inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(inputChar)) {
      // prevent character excepts integers / numbers
      event.preventDefault()
    }
  }

  amountConvertion(amount?: any): any {
    amount = amount?.toString()

    if (amount?.split('.')?.length == 1)
      return amount.replace(/,/g, '')
    else {
      amount = amount?.replace(/,/g, '');
      return amount.split('.')?.[0] + '.' + amount.split('.')?.[1]?.substring(0, 2);
    }
  }

  fetchPermssions() {
    this.permissionsService.loadPermissions(this._storage.getPermssions())
  }

  fileValidation(type: string[], file: any): boolean {
    if (type && file) {
      let extension =
        '.' + file.type.split('/')[file.type.split('/').length - 1]
      return type.indexOf(extension) !== -1
    } else {
      return true
    }
  }

  gotPermission(permission?: any, permissionStatus?: any) {
    if (permissionStatus) {
      try {
        let result = false
        let permissions = this._storage.getPermssions()
        permission = permission ?? []
        permission.forEach((val: any, idx: number) => {
          if (permissions.indexOf(val) !== -1) {
            result = true
          }
        })
        return result
      } catch (ex: any) {
        throw new Error(ex)
      }
    } else {
      return true
    }
  }


  filterSourceConstructor(filterArray: any[], filterKeys: any[]) {
    if (this._lodash.isEmpty(filterArray)) {
      return [];
    }

    return filterArray.map((mapSource: any, index: any) => {
      if (filterKeys[index]) {
        mapSource['key'] = filterKeys[index]['key']; // Used for filter param key
        mapSource['display_key'] = filterKeys[index]['display_key']; // Used for Autocomplete / select dropdown display name
        mapSource['is_IndependentFilter'] = filterKeys[index]['is_IndependentFilter']; // Used for Inputs which want to perform Independent filter
        mapSource['validations'] = filterKeys[index]['validations']; // Used for Inputs validations

        if (mapSource['key']) {
          if (filterKeys[index].hasOwnProperty('sourceKey')) {
            let sourceWithKey: any[] = [];

            // Ensure source is an array before using forEach
            const sourceArray = Array.isArray(filterKeys[index]['source']) ? filterKeys[index]['source'] : [];

            if (!this._lodash.isEmpty(sourceArray)) {
              sourceArray.forEach((source: any) => {
                source = { ...source, sourceKey: filterKeys[index]['sourceKey'] };
                sourceWithKey.push(source); // Used for Autocomplete / select dropdown source Array set
              });
              mapSource['source'] = sourceWithKey;
            }
          } else {
            mapSource['source'] = filterKeys[index]['source']; // Used for Autocomplete / select dropdown source Array set
          }
        }
      }

      return mapSource; // Return the modified object
    });
  }

  filterParamsConstructor(params: object, offset?: number, endValue?: number) {
    let paramValues: any =
      offset !== undefined && endValue !== undefined
        ? `?offset=${offset}&end=${endValue}`
        : '?'
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        if (
          typeof key === typeof 'string' &&
          (typeof value == typeof 'string' || typeof value == 'number') &&
          value !== '' &&
          value != null &&
          value != undefined
        ) {
          paramValues += `&${key}=${value}`
        } else if (value && Array.isArray(value) && value.length) {
          let valString = (
            this._lodash.mapData(
              value,
              this._lodash.getData(value, 'sourceKey', 'id')
            ) || []
          ).toString()
          paramValues += `&${key}=${valString}`
        } else if (value && typeof value === typeof {}) {
          if (value.hasOwnProperty('sourceKey')) {
            /* below will check value type nd return boolean true if value is type of string */
            let valueType = typeof value[value['sourceKey']] === typeof 'string'
            paramValues += `&${key}=${valueType
              ? value[value['sourceKey']]?.toLowerCase()
              : value[value['sourceKey']]
              }`
          } else {
            for (let [i, j] of Object.entries(value)) {
              let date: any = j
              if (moment(date).isValid()) {
                paramValues += `&${i}=${this.datePipe?.transform(
                  date,
                  'yyyy-MM-dd',
                  'en-US'
                )}`
              }
            }
          }
        }
      }
    }
    return paramValues
  }

  mapSerialNumberAndRoutePath(
    startRow = 0,
    actualArray: any,
    actionPaths?: any[],
    deleteActionStatus?: boolean,
    columnSerial: string = 'serial_no'
  ) {
    return actualArray?.map((mapList: any, index: any) => {
      let routeArray: any[] = []
      // mapList[columnSerial] =        ((this._customTable?.currentPage || 1) - 1) * (this._customTable.pageSize || 20) + index + 1;
      mapList[columnSerial] = startRow + index + 1;
      if (actionPaths) {
        actionPaths?.forEach((path: any) => {
          /* below push is for both view and edit */
          routeArray.push({
            mode: path?.page
              ? path?.page
              : path?.route?.includes('view')
                ? 'view'
                : path?.route?.includes('edit')
                  ? 'edit'
                  : '',
            path:
              path?.routeParamStatus === false
                ? path?.route
                : path?.route + mapList?.id,
            status: path?.status,
            routeStatus: path?.routeStatus,
            icon: path?.icon || '',
            queryParams: path?.queryParams || '',
            queryParamsHandling: path?.queryParamsHandling || '',
            tooltip: path?.tooltip || '',
          })
        })
        /* below push is for delete */
        routeArray.push({
          mode: 'delete',
          path: mapList?.id,
          status: deleteActionStatus
        })
        mapList['route'] = routeArray
      }
    })
  }

  getSetRouteValue(data: any): string {
    const prefix = data?.split('/')?.[1]?.toLowerCase();

    // Set routePrefix based on matching user type slug or default to ADMIN_SLUG
    this.routePrefix = Object.values(USERTYPE).includes(prefix) ? prefix : USERTYPE.ADMIN_SLUG;

    return data?.value || {};
  }

  updateStoreProgressStatus(stateConstKeyName: string, storeName: string) {
    console.log('updateStoreProgressStatus: ', stateConstKeyName, storeName)
    if (stateConstKeyName in StateManagement) {
      // if (StateManagement[stateConstKeyName]) {
      this.store.dispatch(setMasterAPIProgressStatus({ 'data': { 'key': storeName, 'isInProgress': true } }));
    }
  }
  updateStoreData(stateConstKeyName: string, storeName: string, storeList: any = []) {
    console.log('updateStoreData: ', stateConstKeyName, storeName, storeList)
    if (stateConstKeyName in StateManagement && storeName) {
      console.log('updateStoreData1: ', stateConstKeyName, storeName, storeList)
      this.store.dispatch(setMasterList({ 'data': { 'key': storeName, 'list': storeList, 'isInProgress': false, 'isLoaded': true } }));
    }
  }

  // getStateLatestValue() {
  //   return this.state.getValue();
  // }

  // getStateMasterData(id:any = [], fieldName:string, mappedFieldName:string) {
  //   this._master.getMasterDataByIds(id.toString(), 'county', 'district')
  // }

  downloadBlobValue(blob: any, fileName: string = 'example.pdf') {//blob based file download
    // Create a link element
    const link = document.createElement('a');

    // Set the link's href attribute to the blob URL
    link.href = URL.createObjectURL(blob);

    // Set the download attribute to specify the filename
    link.download = fileName;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  downloadFile(url: string, filename: string) {//URL based download
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading file:', error));
  }

  /*
  downloadAsPDF(elementID: string, filename: string) {//HTML element download as PDF
    this._loader.show();
    const elementRef: any = document.getElementById(elementID);
    const opt = {
      margin: [4, 7],
      filename: `${filename}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    
    html2pdf()
      .set(opt)
      .from(elementRef)
      .toPdf()
      .output('blob').then((blobValue: any) => {
        this._loader.hide();
        this.downloadBlobValue(blobValue, filename);
      })
        
  }
    */

 

  filePreviewModal(data: any) {
    return this._dailog?.open(FilePreviewComponent, {
      exitAnimationDuration: '50ms',
      width: '580px',
      //panelClass: 'custom-height',
      data: { attachments: data }
    })?.afterClosed();
  }


  generateUniqueFileName(file: File): string {
    const timestamp = new Date().getTime();
    const uniqueName = `${timestamp}_${file.name}`;
    return uniqueName;
  }

  isNotEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  appendMultipleComponentFilesToFormData(files: any, formDataObject: any) {
    if (files.length > 0) {
      files.forEach((selectedFiles: any) => {
        this.appendFilesToFormData(selectedFiles, formDataObject);
      });
    }

    return formDataObject;
  }

  appendFilesToFormData(selectedFiles: any, formDataObject: any) {
        if (this.isNotEmptyObject(selectedFiles)) {
          Object.keys(selectedFiles).forEach(uniqueName => {
            const file = selectedFiles[uniqueName];
            formDataObject.append(uniqueName, file, file.name);
          });
        }

    return formDataObject;
  }

  findLastNestedId(data: any, childKey: string): number | null {
    // Check if the data has the child_key property to determine if there are further children
    if (data[childKey] && Array.isArray(data[childKey]) && data[childKey].length > 0) {
      const lastChild = data[childKey][0]; // Assuming the first element as the relevant child
      if (lastChild.child_keys && lastChild.child_keys.length > 0) {
        // Recursively go deeper based on the first child key
        return this.findLastNestedId(lastChild, lastChild.child_keys[0]);
      } else {
        // Base case: return the current object's id if there are no more children
        return lastChild.id ?? null;
      }
    }
    return null;
  }
  
  // Function to initiate the search from root level
  getLastNestedId(data: any): number | null {
    if (data.child_keys && data.child_keys.length > 0) {
      return this.findLastNestedId(data, data.child_keys[0]);
    }
    return null;
  }
  
  findById(array: any[], id: number): any {
    return array.find(item => item.id === id) || null;
  }

  /**
   * @description
   * Filter array keyname id's............................
   * @param data 
   * @returns 
   */
  transformData(data: any, keyName: string) {
    return data?.map((item: any) => {
      let transformed: any = { id: item.id, key: keyName, display_name: item.display_name };

      if (item.child_keys.length > 0) {
        transformed.child = [];
        item.child_keys.forEach((key: string) => {
          transformed.child = transformed.child.concat(this.transformData(item[key], key));
        });
      }

      return transformed;
    });
  }

  /**
   * @description
   * Nested JSON built as flattern JSON................................
   * @param jsonData 
   * @param deleteMode 
   * @returns 
   */

  flattenJson(jsonData: any, deleteMode: boolean) {
    let result: any = [];

    function flatten(item: any) {
      result.push(item);
      if (item.child) {
        item.child.forEach(flatten);
        if (deleteMode) {
          delete item.child;
        }
      }
    }
    jsonData?.forEach(flatten);
    return result;
  }

  removeSectionByKey(sections: any[], keyToRemove: string): any[] {
    // Filter out the section that matches the key to remove
    return sections.filter(section => section.key !== keyToRemove);
  }

  removeKeyFromObjects(array : any, keyToRemove : any) {
    return array.map((item: any) => {
        // Remove the specified key from the main object
        const { [keyToRemove]: _, ...itemWithoutKey } = item;
        itemWithoutKey['is_copy'] = true;

        // If attachments or other nested arrays exist, map over them and remove the key
        if (itemWithoutKey.attachments && Array.isArray(itemWithoutKey.attachments)) {
            itemWithoutKey.attachments = itemWithoutKey.attachments.map((attachment:any) => {
              attachment['is_copy'] = true;
              return attachment;
          });
        }

        return itemWithoutKey;
    });
  }

  getModuleDataById(id:number, apiEndPoint: any) : Observable<any> {
    let endpoint: any = '';
    if (id) endpoint = `${apiEndPoint}${id}/`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  generatePDF(element:any, print:boolean = false,  fileName:string = "download") {
    this._loader.show();
    html2canvas(element).then((canvas) => {
      this._loader.hide();
      //Remove Existing iframe
      const existIframe:any = document.getElementById("invoice-print");
      if (existIframe) 
        document.body.removeChild(existIframe);

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
  
      // Calculations for PDF format and scaling
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      if (print) {
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
      
        // Create an invisible iframe for printing
        const iframe = document.createElement('iframe');
        iframe.setAttribute("id", "invoice-print");
        iframe.style.display = 'none';
        iframe.src = pdfUrl;
      
        document.body.appendChild(iframe);
      
        iframe.onload = () => {
          iframe.contentWindow?.print(); // Trigger the print dialog
        }; 

      }else
        pdf.save(`${fileName}.pdf`); // Trigger the PDF download

    });
  }

}
