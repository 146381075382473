import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  

  constructor(
    private _route: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute) { }

  navigateBack() {
    return this.location.back();
  }

  navigatePages(path: any, options?: any) {
    return this._route.navigate([path], { state: options?.stateValue, queryParams: options?.queryParams, queryParamsHandling: options?.queryParamsHandling });
  }

  navigate(arg0: string[]) {
    throw new Error('Method not implemented.');
  }
  getResetPasswordToken() {
    let token: any;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("parmas", params);
      if (params['tk']) {
        token = params['tk']
      } else {
        token = this._route.url.split('/')[3]
      }
    });
    return token
  }
}
