import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class LoaderService {

    showLoading = new Subject<boolean>();
    isLoading = new Subject<boolean>();
    loaderShow() {
       this.isLoading.next(true);
    }
    loaderHide() {
       this.isLoading.next(false);
    }

    show() { 
        this.showLoading.next(true);
     }

    hide() { 
        this.showLoading.next(false);
     }
}