import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { formatDate } from '@angular/common';
import { GridApi } from 'ag-grid-community';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import XLSX from 'xlsx';
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
@Injectable({
  providedIn: 'root'
})
export class CustomTableService {

  public gridOptions: any;
  public gridApi: GridApi | any;
  protected paginationParams: any;
  private staticText: any = (textConfiguration as any).default;
  public destory = new Subject<void>();
  public customTotalRecords = new BehaviorSubject<any>(null);
  customTotalRecords$ = this.customTotalRecords.asObservable();
  /* below variables is for pagination */
  public currentPage: any;
  public pageSize: any;

  constructor(private _loader: LoaderService,
    private _http: HttpService,private _lodash: LodashService, @Inject(LOCALE_ID) private locale: string) {
  }

  loadGridOptions() {
    this.gridOptions = {
      ...this.staticText?.common?.table_common_properties?.table_addtional_grid_options,
      "cacheBlockSize": 5,
      onPaginationChanged: (params: any) => this.onPaginationChanged(params),
      getRowStyle: (params: any): any => this.customTableStyleRows(params),
    };
  }

  onPaginationChanged(params: any) {
    this.paginationParams = params;
    this.currentPage = this.gridOptions.api.paginationGetCurrentPage();
  }

  customTableStyleRows(params: any): any {
    if (params.rowIndex % 2 !== 0) { return { backgroundColor: this.staticText?.common?.table_row_bg } }
  }

  csvCallbackFunction(columnDefs?: any, csvParams?: any) {
    let columns = this._lodash.Clonedata(columnDefs);
    csvParams['fileName'] += '_' + formatDate(new Date(), 'yyyyMMdd_HHmmss', this.locale);
    if (columns instanceof Object) {
      delete columns?.ACTION;
      let columnKeys = Object.keys(columns);
      this.gridOptions.api.exportDataAsCsv({ columnKeys: columnKeys, ...csvParams })
    } else {
      this.gridOptions.api.exportDataAsCsv({ ...csvParams })
    }
  }

  onFilterTextBoxChanged(params: any) {
    this.gridOptions.setQuickFilter(
      (document.getElementById('filter') as HTMLInputElement).value
    );
  }

  uploadFromCsv(endpoint:any, ApiMethod:any, formData:any){
    return this._http
    .requestCall(endpoint, ApiMethod, formData)
    .pipe(takeUntil(this.destory))
  }

  exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map((row: any) => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `${cell}`;
          }
          return '" ' + cell + '" '
        }).join(separator);
      }).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  // public exportToExcel(element:any, fileName: string): void {
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element?.nativeElement);
  //   const wscols: XLSX.ColInfo[] = [,,{ wpx: 50 * 7 }];
  //  ws['!cols'] = wscols;
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  XLSX.writeFile(wb, fileName+'.xlsx');
  // }

}
