import * as i0 from '@angular/core';
import { EventEmitter, Component, Output, Input, ViewChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import { getViewerDetails, isLocalFile, replaceLocalUrl, getDocxToHtml, googleCheckSubscription, iframeIsLoaded } from 'docviewhelper';
const _c0 = ["iframe"];
function NgxDocViewerComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHtml", ctx_r0.docHtml, i0.ɵɵsanitizeHtml);
  }
}
function NgxDocViewerComponent_ng_container_0_object_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "object", 5)(1, "p");
    i0.ɵɵtext(2, " Your browser does not support PDFs. ");
    i0.ɵɵelementStart(3, "a", 6);
    i0.ɵɵtext(4, "Download the PDF");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, ". ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("data", ctx_r0.fullUrl, i0.ɵɵsanitizeResourceUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("href", ctx_r0.fullUrl, i0.ɵɵsanitizeUrl);
  }
}
function NgxDocViewerComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgxDocViewerComponent_ng_container_0_div_1_Template, 1, 1, "div", 2)(2, NgxDocViewerComponent_ng_container_0_object_2_Template, 6, 2, "object", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.configuredViewer !== "pdf");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.fullUrl && ctx_r0.configuredViewer === "pdf");
  }
}
function NgxDocViewerComponent_ng_container_1_iframe_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "iframe", 9, 0);
    i0.ɵɵlistener("load", function NgxDocViewerComponent_ng_container_1_iframe_1_Template_iframe_load_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.iframeLoaded());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r0.fullUrl, i0.ɵɵsanitizeResourceUrl);
  }
}
function NgxDocViewerComponent_ng_container_1_div_2_iframe_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "iframe", 12, 0);
    i0.ɵɵlistener("load", function NgxDocViewerComponent_ng_container_1_div_2_iframe_2_Template_iframe_load_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r0.iframeLoaded());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("src", ctx_r0.fullUrl, i0.ɵɵsanitizeResourceUrl);
  }
}
function NgxDocViewerComponent_ng_container_1_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "div");
    i0.ɵɵtemplate(2, NgxDocViewerComponent_ng_container_1_div_2_iframe_2_Template, 2, 1, "iframe", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("background-color", ctx_r0.disableContent === "popout-hide" ? "#fff" : "transparent");
    i0.ɵɵclassProp("overlay-full", ctx_r0.disableContent === "all")("overlay-popout-google", ctx_r0.configuredViewer === "google" && (ctx_r0.disableContent === "popout" || ctx_r0.disableContent === "popout-hide"))("overlay-popout-office", ctx_r0.configuredViewer === "office" && (ctx_r0.disableContent === "popout" || ctx_r0.disableContent === "popout-hide"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.fullUrl);
  }
}
function NgxDocViewerComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgxDocViewerComponent_ng_container_1_iframe_1_Template, 2, 1, "iframe", 7)(2, NgxDocViewerComponent_ng_container_1_div_2_Template, 3, 9, "div", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.fullUrl && ctx_r0.disableContent === "none");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.disableContent !== "none");
  }
}
export * from 'docviewhelper';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
class NgxDocViewerComponent {
  constructor(domSanitizer, ngZone) {
    this.domSanitizer = domSanitizer;
    this.ngZone = ngZone;
    this.loaded = new EventEmitter();
    this.url = '';
    this.queryParams = '';
    this.viewerUrl = '';
    this.googleCheckInterval = 3000;
    this.googleMaxChecks = 5;
    this.disableContent = 'none';
    this.googleCheckContentLoaded = true;
    this.viewer = 'google';
    this.overrideLocalhost = '';
    this.iframes = undefined;
    this.fullUrl = undefined;
    this.externalViewer = false;
    this.docHtml = '';
    this.configuredViewer = 'google';
    this.checkIFrameSubscription = undefined;
    this.shouldCheckIframe = false;
  }
  ngAfterViewInit() {
    if (this.shouldCheckIframe) {
      const iframe = this.iframes?.first?.nativeElement;
      if (iframe) {
        this.shouldCheckIframe = false;
        this.reloadIframe(iframe);
      }
    }
  }
  ngOnDestroy() {
    if (this.checkIFrameSubscription) {
      this.checkIFrameSubscription.unsubscribe();
    }
  }
  async ngOnChanges(changes) {
    if (changes && changes['viewer'] && (changes['viewer'].isFirstChange || changes['viewer'].currentValue !== changes['viewer'].previousValue)) {
      if (this.viewer !== 'google' && this.viewer !== 'office' && this.viewer !== 'mammoth' && this.viewer !== 'pdf' && this.viewer !== 'url') {
        console.error(`Unsupported viewer: '${this.viewer}'. Supported viewers: google, office, mammoth and pdf`);
      }
      this.configuredViewer = this.viewer;
    }
    if (changes['url'] && changes['url'].currentValue !== changes['url'].previousValue || changes['viewer'] && changes['viewer'].currentValue !== changes['viewer'].previousValue || changes['viewerUrl'] && changes['viewerUrl'].currentValue !== changes['viewerUrl'].previousValue) {
      let viewerDetails = getViewerDetails(this.url, this.configuredViewer, this.queryParams, this.viewerUrl);
      this.externalViewer = viewerDetails.externalViewer;
      if (viewerDetails.externalViewer && this.overrideLocalhost && isLocalFile(this.url)) {
        const newUrl = replaceLocalUrl(this.url, this.overrideLocalhost);
        viewerDetails = getViewerDetails(newUrl, this.configuredViewer, this.queryParams, this.viewerUrl);
      }
      this.docHtml = '';
      if (this.checkIFrameSubscription) {
        this.checkIFrameSubscription.unsubscribe();
      }
      if (!this.url) {
        this.fullUrl = undefined;
      } else if (viewerDetails.externalViewer || this.configuredViewer === 'url' || this.configuredViewer === 'pdf') {
        this.fullUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(viewerDetails.url);
        // see:
        // https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives
        // hack to reload iframe if it's not loaded.
        // would maybe be better to use view.officeapps.live.com but seems not to work with sas token.
        if (this.configuredViewer === 'google' && this.googleCheckContentLoaded) {
          this.ngZone.runOutsideAngular(() => {
            // if it's not loaded after the googleIntervalCheck, then open load again.
            const iframe = this.iframes?.first?.nativeElement;
            if (iframe) {
              this.reloadIframe(iframe);
            } else {
              this.shouldCheckIframe = true;
            }
          });
        }
      } else if (this.configuredViewer === 'mammoth') {
        this.docHtml = await getDocxToHtml(this.url);
      }
    }
  }
  reloadIframe(iframe) {
    this.checkIFrameSubscription = googleCheckSubscription();
    this.checkIFrameSubscription.subscribe(iframe, this.googleCheckInterval, this.googleMaxChecks);
  }
  iframeLoaded() {
    const iframe = this.iframes?.first?.nativeElement;
    if (iframe && iframeIsLoaded(iframe)) {
      this.loaded.emit(undefined);
      if (this.checkIFrameSubscription) {
        this.checkIFrameSubscription.unsubscribe();
      }
    }
  }
}
NgxDocViewerComponent.ɵfac = function NgxDocViewerComponent_Factory(ɵt) {
  return new (ɵt || NgxDocViewerComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer), i0.ɵɵdirectiveInject(i0.NgZone));
};
NgxDocViewerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxDocViewerComponent,
  selectors: [["ngx-doc-viewer"]],
  viewQuery: function NgxDocViewerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.iframes = _t);
    }
  },
  inputs: {
    url: "url",
    queryParams: "queryParams",
    viewerUrl: "viewerUrl",
    googleCheckInterval: "googleCheckInterval",
    googleMaxChecks: "googleMaxChecks",
    disableContent: "disableContent",
    googleCheckContentLoaded: "googleCheckContentLoaded",
    viewer: "viewer",
    overrideLocalhost: "overrideLocalhost"
  },
  outputs: {
    loaded: "loaded"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 2,
  vars: 2,
  consts: [["iframe", ""], [4, "ngIf"], [3, "innerHtml", 4, "ngIf"], ["type", "application/pdf", "width", "100%", "height", "100%", 3, "data", 4, "ngIf"], [3, "innerHtml"], ["type", "application/pdf", "width", "100%", "height", "100%", 3, "data"], [3, "href"], ["id", "iframe-doc-viewer", "frameBorder", "0", 3, "src", "load", 4, "ngIf"], ["class", "container", 4, "ngIf"], ["id", "iframe-doc-viewer", "frameBorder", "0", 3, "load", "src"], [1, "container"], ["id", "iframe", "frameBorder", "0", 3, "src", "load", 4, "ngIf"], ["id", "iframe", "frameBorder", "0", 3, "load", "src"]],
  template: function NgxDocViewerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, NgxDocViewerComponent_ng_container_0_Template, 3, 2, "ng-container", 1)(1, NgxDocViewerComponent_ng_container_1_Template, 3, 2, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.externalViewer);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.externalViewer);
    }
  },
  dependencies: [i2.NgIf],
  styles: ["[_nghost-%COMP%]{display:block}.container[_ngcontent-%COMP%]{width:100%;height:100%;position:relative}.overlay-popout-google[_ngcontent-%COMP%]{width:40px;height:40px;right:26px;top:11.5px;position:absolute;z-index:1000}.overlay-popout-office[_ngcontent-%COMP%]{width:100px;height:20px;right:0;bottom:0;position:absolute;z-index:1000}.overlay-full[_ngcontent-%COMP%]{width:100%;height:100%;right:0;top:0;position:absolute;z-index:1000}iframe[_ngcontent-%COMP%]{width:100%;height:100%}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxDocViewerComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-doc-viewer',
      template: "<ng-container *ngIf=\"!externalViewer\">\n  <div *ngIf=\"configuredViewer !== 'pdf'\" [innerHtml]=\"docHtml\"></div>\n  <object\n    *ngIf=\"fullUrl && configuredViewer === 'pdf'\"\n    [data]=\"fullUrl\"\n    type=\"application/pdf\"\n    width=\"100%\"\n    height=\"100%\"\n  >\n    <p>\n      Your browser does not support PDFs.\n      <a [href]=\"fullUrl\">Download the PDF</a>.\n    </p>\n  </object>\n</ng-container>\n<ng-container *ngIf=\"externalViewer\">\n  <iframe\n    (load)=\"iframeLoaded()\"\n    *ngIf=\"fullUrl && disableContent === 'none'\"\n    #iframe\n    id=\"iframe-doc-viewer\"\n    frameBorder=\"0\"\n    [src]=\"fullUrl\"\n  ></iframe>\n  <div class=\"container\" *ngIf=\"disableContent !== 'none'\">\n    <div\n      [class.overlay-full]=\"disableContent === 'all'\"\n      [class.overlay-popout-google]=\"\n        configuredViewer === 'google' &&\n        (disableContent === 'popout' || disableContent === 'popout-hide')\n      \"\n      [class.overlay-popout-office]=\"\n        configuredViewer === 'office' &&\n        (disableContent === 'popout' || disableContent === 'popout-hide')\n      \"\n      [style.background-color]=\"\n        disableContent === 'popout-hide' ? '#fff' : 'transparent'\n      \"\n    ></div>\n    <iframe\n      (load)=\"iframeLoaded()\"\n      *ngIf=\"fullUrl\"\n      #iframe\n      id=\"iframe\"\n      frameBorder=\"0\"\n      [src]=\"fullUrl\"\n    ></iframe>\n  </div>\n</ng-container>\n",
      styles: [":host{display:block}.container{width:100%;height:100%;position:relative}.overlay-popout-google{width:40px;height:40px;right:26px;top:11.5px;position:absolute;z-index:1000}.overlay-popout-office{width:100px;height:20px;right:0;bottom:0;position:absolute;z-index:1000}.overlay-full{width:100%;height:100%;right:0;top:0;position:absolute;z-index:1000}iframe{width:100%;height:100%}\n"]
    }]
  }], function () {
    return [{
      type: i1.DomSanitizer
    }, {
      type: i0.NgZone
    }];
  }, {
    loaded: [{
      type: Output
    }],
    url: [{
      type: Input
    }],
    queryParams: [{
      type: Input
    }],
    viewerUrl: [{
      type: Input
    }],
    googleCheckInterval: [{
      type: Input
    }],
    googleMaxChecks: [{
      type: Input
    }],
    disableContent: [{
      type: Input
    }],
    googleCheckContentLoaded: [{
      type: Input
    }],
    viewer: [{
      type: Input
    }],
    overrideLocalhost: [{
      type: Input
    }],
    iframes: [{
      type: ViewChildren,
      args: ['iframe']
    }]
  });
})();
class NgxDocViewerModule {}
NgxDocViewerModule.ɵfac = function NgxDocViewerModule_Factory(ɵt) {
  return new (ɵt || NgxDocViewerModule)();
};
NgxDocViewerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxDocViewerModule
});
NgxDocViewerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxDocViewerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NgxDocViewerComponent],
      exports: [NgxDocViewerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxDocViewerComponent, NgxDocViewerModule };

