export const environment = {
  production: false,
  skipngRok: false,
  enableLoginOtp: false,
  otpDuration: 1,
  apiUrl: "https://api.usp-dev.easyngo.com/",
  websiteLandingUrl: "",
  enableCaptcha: false,
  attchment: {
    extension: ["jpg", "png", "doc", "xls", "xlxs", "ppt", "pdf"],
    size: 104857600
  }
};
