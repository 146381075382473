export enum ApiMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    DOWNLOAD = "DOWNLOAD",
    GETPARAMS = "GETPARAMS",
    DOWNLOAD_PARAMS = "DOWNLOAD_PARAMS",
    DOWNLOAD_BLOB = "DOWNLOAD_BLOB"
}

export enum NAVIGATIONID {
    VALUATION_PROPERTY = 1,
    CADASTRAL = 8,
    COUNTY = 4,
    METHODOLOGY_PROPERTY = 2
}

export enum KeyCloak {
    KEYCLOAK_REALM = "lavmis",
    KEYCLOAK_CLIENTID = "lavmis-client"
}

export enum BaseURL {
    KEYCLOAK_BASE_URL = "https://lavmis-keycloak.dev.easyngo.com/",
    KEYCLOAK_API_URL = "https://lavmis.dev.easyngo.com/api/",

    KEYCLOAK_BASE_AUTHENTICATE = "https://lavmis-keycloak.dev.easyngo.com/realms/lavmis/",
}

export const StateManagement = {
    region: false,
    mzo: false,
    district: false,
    county: false,
    sub_county: false,
    parish: false,
    village: false,
    valuvationType: false
}

export enum Endpoints {

    // tax management..................
    TAX_STAMP = "onboard/tax-stamp/",
    TAX_SLAP = "onboard/tax-slab/",
    COMMENTS_CREATE = 'onboard/self-registration-comment/',
    COMMENTS = 'onboard/self-registration-comment/get-registration-comment/',
    ONBOARD_COMMENTS_CREATE = 'onboard/onboard-comment/',
    ONBOARD_COMMENTS = 'onboard/onboard-comment/get-onboard-comment/',
    LOGOUT = 'iam/logout/',
    AUDIT_LOG = "iam/audit-log/",
    WORKFLOW_STATE = "/river/state/",
    WORKFLOW_STATE_LIST = "/river/state/get-state-list/",
    WORKFLOW_TRANSITION = "onboard/workflow/transition/",
    WORKFLOW_DIRECTION = "onboard/workflow/transition/transition-direction-details/",
    WORKFLOW_LIST = "onboard/workflow/proceeding_meta/",
    Workflow_Model_Details = "/river/proceeding_meta/workflow-model-details/",
    WORKFLOW_TRANSITION_DATA = '/river/workflow/',
    GET_USER_LIST = "employees",
    GET_PRODUCT_LIST = "products",

    /* SCP Product */
    SCP_PRODUCT = "onboard/scp-product/",

    /* auth tokens */
    POST_REFRESH_TOKEN_AUTH = "iam/token/refresh/",
    POST_TOKEN_AUTH = "iam/api-token-auth/",
    AUTH_LOGIN = "iam/api-token-auth/",
    TOKEN_VERIFY_AUTH = "iam/token/verify/",

    /* Forecast  */
    FORECAST_TAX_STAMP = "onboard/forecast/",

    /* SignUp */

    POST_SIGNUP = "iam/users/create-private-valuer/",


    /* Region */

    GET_LOCATION_LIST = "iam/location-master/",
    GET_LOCATION_CATEGORY_LIST = "iam/location-category/",
    GET_LOCATION_LEVEL_LIST = "iam/location-level/",
    GET_CHILD_LOCATION_LIST = "iam/location-master/get-child-locations/",
    GET_USER_LISTS = "iam/users/",
    GET_PRIVATE_LIST = "iam/users/private-valuer-pending-list/",
    GET_USER_LISTS_WITHOUT_PERMISSION = "iam/get-users/",
    /* user management - entity*/
    GET_ENTITY_LIST = "iam/user-type/",

    /* user management - roles*/
    GET_ROLES_LIST = "iam/roles/",
    GET_KEYCLOCK_PERMISSION_LIST = "iam/permissions/",

    /* Master - location*/
    GET_DROPDOWN_ENTITY = "iam/get-usertype/",
    GET_DROPDOWN_ROLES = "iam/get-roles/",
    GET_DROPDOWN_USERS_LIST = "iam/get-users/",
    GET_DROPDOWN_REGION_LIST = "onboard/get-region/",
    GET_DROPDOWN_MZO_LIST = "onboard/get-zone/",
    GET_DROPDOWN_DISTRICT_LIST = "onboard/get-district/",
    GET_DROPDOWN_COUNTY_LIST = "onboard/get-county/",
    GET_DROPDOWN_SUBCOUNTY_LIST = "onboard/get-subcounty/",
    GET_DROPDOWN_PARISH_LIST = "onboard/get-parish/",
    GET_DROPDOWN_VILLAGE_LIST = "onboard/get-village/",

    /**PRODUCT  Type*/

    PRODUCT_TYPE_LIST = "onboard/product-type/",
    PRODUCT_TYPE_DROPDOWN = "onboard/get-product-type/",


    /**PRODUCT CATEGORY */
    PRODUCT_CATEGORY_LIST = "onboard/product-category/",
    PRODUCT_CATEGORY_DROPDOWN = "onboard/get-product-category/",

    /**PRODUCT PACKAGING TYPE */
    PRODUCT_PACKAGING_TYPE_LIST = "onboard/product-package/",

    /**Product UOM  */

    PRODUCT_UOM_LIST = "onboard/product-uom/",


    /**Product SCP LIST */
    GET_DROPDOWN_BRAND_NAME = "onboard/get-product-brand/",
    GET_PRODUCT_NEXT_STATUS = "onboard/scp-product/get-next-product-status/",
    UPDATE_PRODUCT_STATUS = "onboard/scp-product/update-product-status/",
    GET_SITE_DROPDOWN ="onboard/get-sites/",
    GET_PRODUCT_HISTORY = "onboard/scp-product/get-product-status-history",


    /* GEO  */
    GET_GEO_MINIMAL_LAYER_LIST = "onboard/get-geo-layer-minimal/",


    /* Configuration building details */

    /* Workflow */
    GET_VALUATION_TYPE = "onboard/masters/valuation-type/",
    POST_FORGOT_PASSWORD = "iam/password/reset-request/",
    POST_PASSWORD_RESET = "iam/password/reset-confirm/",
    POST_PASSWORD_UPDATE = "iam/password/update-password/",
    POST_PASSWORD_RESET_VERIFY = "iam/password/reset-verify/",

    // Location Dropdown
    GET_LOCATION_DROPDOWN = 'iam/get-location-master/',

    // SCP
    GET_LICENSE_TYPE = 'onboard/get-license-type/',
    GET_OWNERSHIP_TYPE = 'onboard/get-ownership/',
    BUSINESS_VERIFY = 'onboard/self-registration/get-registration-otp/',
    VERIFY_OTP = 'onboard/self-registration/verify-registration-otp/',
    GET_REGISTRATION_CERTIFICATES = 'onboard/get-certificate/',
    CREATE_REGISTERATION_API = 'onboard/self-registration/',
    GET_SCP_LIST = "onboard/self-registration/",
    GET_DROPDOWN_STATUS = "onboard/self-registration/get-registration-status/",
    GET_DROPDOWN_COMPANY = "onboard/get-scp-company/",
    GET_ONBOARD_DROPDOWN_STATUS = "onboard/onboard/get-onboard-status/",
    GET_NEXT_STATUS = "onboard/self-registration/get-next-registration-status/",
    GET_ONBOARDING_NEXT_STATUS = "onboard/onboard/get-next-onboard-status/",
    GET_DROPDOWN_LICENSE = "onboard/get-license-type/",
    GET_SCP_REGN_DETAIL = "onboard/self-registration/",
    UPDATE_SCP_STATUS = "onboard/self-registration/update-registration-status/",
    UPDATE_ONBOARD_STATUS = "onboard/onboard/update-onboard-status/",
    GET_CATEGORY_DROPDOWN = 'iam/get-location-category/',
    GET_LOCATION_LEVEL = 'iam/get-location-level/',
    GET_PRODUCT_TYPE = 'onboard/get-product-type/',
    GET_PRODUCT_CATEGORY = 'onboard/get-product-category/',
    GET_PRODUCT_CATEGORY_UOM = 'onboard/get-product-uom/',
    GET_PRODUCT_VOLUME_UOM = "onboard/get-product-volume/",
    GET_PRODUCT_CATEGORY_PACKAGE = 'onboard/get-product-package/',
    GET_SCB_REGN_CARD_STATUS = 'onboard/self-registration/get-registration-card-status/',
    GET_TAX_STAMP = 'onboard/get-tax-stamp-details/',
    GET_DROPDOWN_TAX_STAMP = 'onboard/get-tax-stamp-product-details/',
    GET_PRODUCT_BRAND_DROPDOWN = 'onboard/get-product-brand/?site=:refID',    
    GET_YEAR_DROPDOWN = 'onboard/get-year/',
    GET_SCP_REGN_HISTORY = 'onboard/self-registration/get-registration-status-history-data',

    // onboarding
    ONBOARD_SCP_USER = 'onboard/onboard/', 
    ONBOARD_PROCESS = 'onboard/onboard/process/', 
    ONBOARD_PROCESS_NEXT_STATUS = 'onboard/onboard/get-next-onboard-status/', 
    ONBOARD_PROCESS_STATUS_CHANGE = 'onboard/onboard/update-onboard-status/', 
    ONBOARD_SITE_DROPDOWN = 'onboard/get-sites/?company=:refID',
    ONBOARD_LOGINUSER_SITE_DROPDOWN = 'onboard/get-sites/?is_approved=true',
    ONBOARD_ACCOUNT_TYPE_DROPDOWN = 'onboard/get-account-type/',
    GET_PRODUCT_DROPDOWN = 'onboard/get-product/?is_all=true&company=:refID',
    GET_PRODUCT_BY_SITE_DROPDOWN = 'onboard/get-product/?site=:refID',
    GET_SCP_ONBOARD_HISTORY = 'onboard/onboard/get-onboard-status-history-data',
    GET_SCP_OVERALL_ONBOARD_HISTORY = 'onboard/onboard/get-status-history-data',

    GET_GRIEVANCE = 'onboard/grievance-grievance/',
    GET_GRIEVANCE_WEB = 'onboard/grievance-web/',
    GET_GRIEVANCE_NEXT_STATUS = 'onboard/grievance-web/get-next-grievance-status/',
    GRIEVANCE_UPDATE_STATUS = 'onboard/grievance-web/update-grievance-status/',
    GET_GRIEVANCE_TYPE = 'onboard/get-grievance-type/',
    GET_GRIEVANCE_HISTORY = "onboard/grievance-web/get-grievance-status-history-data",
    GET_DROPDOWN_GRIEVENCE_STATUS = "onboard/grievance-web/get-grievance-status/",

    GET_FORECAST_STATUS = 'onboard/forecast/get-forecast-status/',
    GET_FORECAST_UPDATE_STATUS = 'onboard/forecast/update-forecast-status/',
    GET_FORECAST_BULK_UPDATE_STATUS = 'onboard/forecast/bulk-forecast-status-update/',
    GET_FORECAST_NEXT_STATUS = 'onboard/forecast/get-next-forecast-status/',
    GET_FORECAST_HISTORY = "onboard/forecast/get-forecast-status-history",

}

export enum colorCodes {
    SUCCESS = "success",
    WARNING = "Warning",
    ERROR = "Error",
    INFO = "Info"
}

export enum COMPLAINTDependentKeys {

    complaint_category = "valuation"
}

export enum ErrorCodes {
    HTTP_200_SUCCESS = 200,
    HTTP_201_CREATED = 201,
    HTTP_208_REPORTED = 208,
    HTTP_204_SUCCESS_NO_CONTENT = 204,
    HTTP_RESET_CONTENT = 205,
    HTTP_400_BAD_REQUEST = 400,
    HTTP_401_UNAUTHORIZED = 401,
    HTTP_403_FORBIDDEN = 403,
    HTTP_404_NOT_FOUND = 404,
    HTTP_500_ERROR = 500,
    HTTP_CONN_REFUSED = 0,
    HTTP_422_UNPROCESSABLE_ENTITY = 422,
    INVALID_USER_CREDENTIALS = "No active account found with the given credentials",
    REFRESH_TOKEN_EXPIRY_ERRCODE = "token_not_valid",
}

export enum ErrorMessage {
    UNKNOWN_ERROR_CODE = 'Unknown Error Code',
    SERVER_UNAVAILABLE = "Server is unavailable",
    INTERNAL_SERVER_ERROR = "Internal Server Error",
    SESSION_EXPIRED = "Session Expired",
    INVALID_USER_CREDENTIALS = "Invalid UserName or Password",
    REFRESH_TOKEN_EXPIRY = "Token has expired",
    // REFRESH_TOKEN_EXPIRY = "Given token not valid for any token type",
    AUTHORIZATION_TOKEN_ERROR = "Please provide the authorization token",
    AUTH_TOKEN_ERROR = "Token is invalid or expired",

    /* user management roles permission error msg */
    ROLES_PERMISSION_ERROR_MSG = "Please select at least one permission.",
    VALUATION_UNAVAILABLE = "There is no records in the selected valuation",
    VALUATION_ACKNOWLEDGE_FIELD_VISIT_ERROR = "Please schedule field visit plan for all property",

    /** Buliding Details */
    INAVLID_TYPE_ERROR_MSG = "Failed to create",
    INVALID_BULK_ACTION_ERROR_MSG_1 = "Please ensure all selected requests are in the same status",



}

export enum ErrorKeys {
    NON_FIELD_ERRORS = 'non_field_errors'
}

export enum SuccessMessage {

    // draft report action 
    SELECT_KEY_PERSON = "Select Anyone Key Person",

    // draft report action 
    DRAFT_ACTION_WARNNING_MSG = "Select Anyone Action",

    /**forgot */
    RESENT_LINK = "Reset link sent successfully",
    /* reset */
    RESET_PASSWORD_MSG = "Success! Password has been reset successfully",


    /* login */
    LOGIN_SUBMIT_MSG = "Success! Logged in successfully",

    PRIVATE_VALUER_REJECT = "Private Valuer Reject",
    COMPARABLE_REJECT_MSG = "Comparable Rate Rejected",

    /**Signup */
    SIGNUP_SUBMIT_MSG = "Private Valuer Created Successfully",
    PRIVATE_VALUER_APPROVE_MSG = "Private Valuer has been Approved Successfully",
    PRIVATE_VALUER_REJECT_MSG = "Private Valuer has been Rejected Successfully",



    /* users list */
    USER_CREATE_MSG = "New user has been created successfully.",
    USER_EDIT_MSG = "User details has been updated successfully.",
    USER_RESET_PASSWORD_MSG = "Password reset link has been sent to",

    /* user management - entity*/
    ENTITY_ADD_MSG = "New User Group detail has been added successfully.",
    ENTITY_UPDATE_MSG = "User Group detail has been updated successfully.",
    USERS_UPDATE_MSG = "User detail has been updated successfully.",
    /* user management - roles*/
    ROLES_ADD_MSG = "New Role has been added successfully.",
    ROLES_UPDATE_MSG = "Role has been updated successfully.",

    /* Configuration - location */

    LOCATION_ADD_MSG = "New location  detail has been added successfully.",
    LOCATION_UPDATE_MSG = "Location  has been updated successfully.",
    LOCATION_DELETE_MSG = "Location  has been deleted successfully.",

    LOCATION_CATEGORY_ADD_MSG = "New location category detail has been added successfully",
    LOCATION_CATEGORY_UPDATE_MSG = "Location category has been updated successfully",
    LOCATION_CATEGORY_DELETE_MSG = "Location category has been deleted successfully",

    /* Configuration - location */
    LOCATION_LEVEL_ADD_MSG = "New location level has been added successfully",
    LOCATION_LEVEL_UPDATE_MSG = "Location level has been updated successfully",
    LOCATION_LEVEL_DELETE_MSG = "Location level has been deleted successfully",

    /* State management - entity*/
    STATE_ADD_MSG = "New State detail has been added successfully",
    STATE_UPDATE_MSG = "State detail has been updated successfully",
    STATE_DELETE_MSG = "State detail has been Deleted successfully",

    /* Transition management - entity*/
    TRANSITION_ADD_MSG = "New Transition detail has been added successfully",
    TRANSITION_UPDATE_MSG = "Transition detail has been updated successfully",
    TRANSITION_DELETE_MSG = "Transition detail has been Deleted successfully",

    /* Transition management - entity*/
    WORKFLOW_ADD_MSG = "New Workflow detail has been added successfully",
    WORKFLOW_UPDATE_MSG = "Workflow detail has been updated successfully",
    WORKFLOW_DELETE_MSG = "Workflow detail has been Deleted successfully",

    /* File Registry*/
    FILEREGISTRY_ADD_MSG = "New File Registry detail has been added successfully",
    FILEREGISTRY_UPDATE_MSG = "File Registry detail has been updated successfully",
    FILEREGISTRY_DELETE_MSG = "File Registry detail has been Deleted successfully",


    /**Product Type  */

    PRODUCT_TYPE_ADD_MSG = "New Product Type has been added successfully",
    PRODUCT_TYPE_UPDATE_MSG = "Product Type has been updated successfully",
    PRODUCT_TYPE_DELETE_MSG = "Product Type has been Deleted scuccessfully",

    /**PRODUCT PACKAGING */
    PRODUCT_PACKAGING_ADD_MSG = "New Product Packaging has been added successfully",
    PRODUCT_PACKAGING_UPDATE_MSG = "Product Packaging has been updated successfully",
    PRODUCT_PACKAGING_DELETE_MSG = "Product Packaging has been Deleted scuccessfully",

    /**PRODUCT UOM */
    PRODUCT_UOM_ADD_MSG = "New Product UOM has been added successfully",
    PRODUCT_UOM_UPDATE_MSG = "Product UOM has been updated successfully",
    PRODUCT_UOM_DELETE_MSG = "Product UOM has been Deleted scuccessfully",



    /**PRODUCT UOM */
    PRODUCT_ADD_MSG = "New Product has been added successfully",
    PRODUCT_UPDATE_MSG = "Product has been updated successfully",
    PRODUCT_DELETE_MSG = "Product has been Deleted scuccessfully",



    PRODUCT_CATEGORY_ADD_MSG = "New Product Category has been added successfully",
    PRODUCT_CATEGORY_UPDATE_MSG = "Product Category has been updated successfully",
    PRODUCT_CATEGORY_DELETE_MSG = "Product Category has been Deleted scuccessfully",

    /* Email Templates  */

    TEMPLATE_CREATED = 'New Template Created Successfully',
    TEMPLATE_UPDATED = 'Template Details Updated Successfully',
    TEMPLATE = 'Please Add Template Content',
    TEMPLATE_SUBJECT = 'Please Add Subject ',

    STATUS_UPDATED = "You have successfully approved the self-registration request.",

}

export enum FailedMessage {
    /*Valuation Search Property*/
    DUPLICATE_PROP_ZONE = "Properties should be same MZO",

    PERMISSION_FAILED_MSG = "You don't have permission to do this action",

    /* login */
    LOGIN_ERROR_MSG = "Failed! Login failed",

    /* users list */
    USER_NOT_CREATE_MSG = "Failed! User not created",
    USER_UPDATE_FAILED_MSG = "Failed! User update failed",

    /* Roles List */
    ROLE_ADD_FAILED_MSG = "Failed! Role not created",
    ROLE_UPDATE_FAILED_MSG = "Failed! Role update created",
    RESET_PASSWORD_FAILED_MSG = "RESET_PASSWORD_FAILED_MSG",


    /* File Upload*/

    FILE_ERR_MSG = "File size must not exceed 25MB",
    FILE_NOT_FOUND_MSG = "No Attachment Found",

    /**Private Valuer */


}

export enum PagePermssions {
    SEND_MAIL_NOTIFICATION = 'send_mail_notification',
    SIGNATUREPADPERMISSION = 'capture_signature',
    VIEW_AUDIT = 'view_audit',

    //dashboard
    DOWNLOAD_DASHBOARD_LIST = "download_dashboard",


    /* Menu permssions */
    ADMIN_DASHBOARD = 'admin_dashboard',
    BUYERS_DASHBOARD = 'buyers_dashboard',
    SUPPLIER_DASHBOARD = 'supplier_dashboard',
    CLERK_DASHBOARD = 'dashboard',
    DASHBOARD_COMMON = "view_dashboard",

    /* Spatial permissions */
    SPATIAL_MENU_PERMISSION = "spatial_data_management",
    DAILYCASE_MENU_PERMISSION = "view_dcm",
    DOWNLOAD_DAILY_CASE = "download_dcm",

    /* users permissions */
    GET_USER_LIST_PERMISSION = "view_customuser",
    ADD_USER_PERMISSION = "add_customuser",
    CHANGE_USER_PERMISSION = "change_customuser",
    DELETE_USER_PERMISSION = 'delete_customuser',

    /**Private VALUER */
    VIEW_APPROVE_PRIVATE_VALUER = "can_approve_private_valuer",
    CAN_VIEW_PRIVATE_VALUER = "can_view_private_valuer",


    // user permission

    VIEW_CUSTOMUSER = "view_customuser",
    ADD_CUSTOMUSER = "add_customuser",
    CHANGE_CUSTOMUSER = "change_customuser",
    DELETE_CUSTOMUSER = "delete_customuser",

    // role permission

    VIEW_CUSTOMGROUP = "view_customgroup",
    ADD_CUSTOMGROUP = "add_customgroup",
    CHANGE_CUSTOMGROUP = "change_customgroup",
    DELETE_CUSTOMGROUP = "delete_customgroup",

    // entity permission

    VIEW_ENTITY = "view_usertype",
    ADD_ENTITY = "add_usertype",
    CHANGE_ENTITY = "change_usertype",
    DELETE_ENTITY = "delete_usertype",

    ///SCP permisssion

    VIEW_SCP = "view_companyinfo",
    ADD_SCP = "add_companyinfo",
    CHANGE_SCP = "change_companyinfo",
    UPDATE_SCP_STATUS = "update_company_status",

    ///Onboarding
    VIEW_ONBOARD = "view_siteinfo",
    ADD_ONBOARD = "add_siteinfo",
    CHANGE_ONBOARD = "change_siteinfo",
    UPDATE_SCP_ONBOARD_STATUS = "change_onboardstatusinfo",

    //product type Permission
    VIEW_PRODUCT_TYPE = "view_producttype",
    ADD_PRODUCT_TYPE = "add_producttype",
    CHANGE_PRODUCT_TYPE = "change_producttype",

    //product category  Permission

    VIEW_PRODUCT_CATEGORY = "view_productcategory",
    ADD_PRODUCT_CATEGORY = "add_productcategory",
    CHANGE_PRODUCT_CATEGORY = "change_productcategory",

    //Product UOM Permission
    VIEW_PRODUCT_UOM = "view_productuom",
    ADD_PRODUCT_UOM = "add_productuom",
    CHANGE_PRODUCT_UOM = "change_productuom",

    //Product Package Permission
    VIEW_PRODUCT_PACKAGE = "view_productpackage",
    ADD_PRODUCT_PACKAGE = "add_productpackage",
    CHANGE_PRODUCT_PACKAGE = "change_productpackage",

    // workflow management
    ADD_STATE = 'can_add_state',
    CHANGE_STATE = 'can_update_state',
    VIEW_STATE = 'can_view_state',
    DELETE_STATE = 'can_delete_state',

    CHANGE_TRANSITION = 'change_transition',
    DELETE_TRANSITION = 'delete_transition',
    VIEW_TRANSITION = 'view_transition',
    ADD_TRANSITION = 'add_transition',

    CUSTOM_ADD_PROCEEDINGMETA = 'custom_add_proceedingmeta',
    CUSTOM_CHANGE_PROCEEDINGMETA = 'custom_change_proceedingmeta',
    CUSTOM_DELETE_PROCEEDINGMETA = 'custom_delete_proceedingmeta',
    CUSTOM_VIEW_PROCEEDINGMETA = 'custom_view_proceedingmeta',

    // configuration - location permission

    ADD_LOCATION_MASTER = 'add_groupedlocation',
    VIEW_LOCATION_MASTER = 'view_groupedlocation',
    UPDATE_LOCATION_MASTER = 'change_groupedlocation',
    DELETE_LOCATION_MASTER = 'delete_location_master',


    VIEW_LOCATION_MENU = "view_locationmaster",
    CHANGE_LOCATION_MENU = "change_locationmaster",
    ADD_LOCATION_MENU = "add_locationmaster",


    VIEW_LOCATION_CATEGORY = "view_locationcategory",
    CHANGE_LOCATION_CATEGORY = "change_locationcategory",
    ADD_LOCATION_CATEGORY = "add_locationcategory",


    VIEW_LOCATION_LEVEL = "view_locationlevel",
    CHANGE_LOCATION_LEVEL = "change_locationlevel",
    ADD_LOCATION_LEVEL = "add_locationlevel",




    DMS_ADD_DOCUMENTS = 'can_add_documents',
    DMS_VIEW_DOCUMENT_LIST = 'can_view_document_list',
    DMS_APPROVE_REJECT_DOCUMENT = 'can_approved_and_reject_document',

    /* DMS view attachement screen */

    CHANGE_DMS_ATTCHMENTS = 'change_dms',
    VIEW_DMS_ATTCHMENTS = 'view_dms',
    DELETE_DMS_ATTCHMENTS = 'delete_dms',
    DMS_APPROVE_REJECT = 'dms.can_approve_reject_document',
    DMS_SAVE_AS_DRAFT = 'dms.can_draft_submit_documet',

    ///DMS Updated Permission         
    ADD_DOCUMENT_REPOSITORY = 'add_document_repository',
    VIEW_DOCUMENT_REPOSITORY = 'view_document_repository',
    UPDATE_DOCUMENT_REPOSITORY = 'change_document_repository',
    DELETE_DOCUMENT_REPOSITORY = 'delete_document_repository',

    ///DMS Document create Screen 

    ADD_DOCUMENT_ATTACHMENT = 'add_document_attachments',
    VIEW_DOCUMENT_ATTACHMENT = 'view_document_attachments',
    UPDATE_DOCUMENT_ATTACHMENT = 'change_document_attachments',
    DELETE_DOCUMENT_ATTACHMENT = 'delete_document_attachments',
    CAN_DRAFT_AND_SUBMIT_DOCUMENT = 'can_draft_and_submit_document',
    CAN_APPROVED_AND_REJECT_DOCUMENT = 'can_approved_and_reject_document',

    CAN_ADD_REGISTRATIONCOMMENT = 'add_registrationcomment',
    CAN_VIEW_REGISTRATIONCOMMENT = 'view_registrationcomment',

    CAN_ADD_ONBOARDCOMMENT = 'add_onboardcomment',
    CAN_VIEW_ONBOARDCOMMENT = 'view_onboardcomment',

    // tax management

    ADD_TAX_STAMP = "add_taxstamp",
    VIEW_TAX_STAMP = "view_taxstamp",
    CHANGE_TAX_STAMP = "change_taxstamp",
    DELETE_TAX_STAMP = "delete_taxstamp",

    ADD_TAX_SLAB = "add_taxslab",
    VIEW_TAX_SLAB = "view_taxslab",
    CHANGE_TAX_SLAB = "change_taxslab",
    DELETE_TAX_SLAB = "delete_taxslab",

    // SCP Product
    ADD_SCP_PRODUCT = "add_product",
    VIEW_SCP_PRODUCT = "view_product",
    CHANGE_SCP_PRODUCT = "change_product",
    DELETE_SCP_PRODUCT = "delete_product",
    CAN_VIEW_PRODUCT_COMMENT = 'view_productcomment',
    UPDATE_PRODUCT_STATUS = "update_product_status",


    //Grievance
    ADD_GRIEVANCE = "add_grievance",
    VIEW_GRIEVANCE = "view_grievance",
    CHANGE_GRIEVANCE = "change_grievance",
    DELETE_GRIEVANCE = "delete_grievance",
    UPDATE_STATUAS_GRIEVANCE = "update_grievance_status",

    //Forecast
    ADD_FORECAST = "add_forecast",
    VIEW_FORECAST = "view_forecast",
    CHANGE_FORECAST = "change_forecast",
    DELETE_FORECAST = "delete_forecast",
    UPDATE_STATUAS_FORECAST = "update_forecast_status",

    //Order
    ADD_ORDER = "add_order",
    VIEW_ORDER = "view_order",
    CHANGE_ORDER = "change_order",
    DELETE_ORDER = "delete_order",
    UPDATE_STATUS_ORDER = "update_order_status",

}

export enum FORMAT {
    DATE = 'MMM dd, YYYY',
    DATE_TIME = 'dd/MM/YYYY  ,     hh:mm a',
    DATE_FORMAT = 'dd/MM/YYYY'
}

/* route paths */

export enum routePath {


    /* app routings */
    AUTH_PATH = "auth",
    AUTH_LOGIN_REDIRECT_PATH = "/auth/login",

    PAGES = "/basic",
    VIEW_PATH = "view",
    ERROR_403_PATH = "403",
    ERROR_404_PATH = "404",
    ERROR_500_PATH = "500",
    VIEW_REDIRECT_403_PATH = "view/403",
    VIEW_REDIRECT_404_PATH = "view/404",
    VIEW_REDIRECT_500_PATH = "view/500",

    /* auth module routings */

    LOGIN_PATH = "login",
    FORGOT_PATH = "forgot",
    RESET_PATH = "reset",
    RESET_PARAM_PATH = "reset/:id",
    LOGIN_REDIRECT_PATH = "/auth/login",
    FORGOT_REDIRECT_PATH = "/auth/forgot",
    RESET_REDIRECT_PATH = "/auth/reset/",
    CHANGE_PASSWORD_REDIRECT_PATH = "/auth/change-password/",

    WORKFLOW = "/workflow/master",
    UNDER_DEVELOPMENT = '/under-developement',
    USER_WORKFLOW = "/workflow",
    USER_WORKFLOW_STATES = "/workflow/states",
    USER_WORKFLOW_TRANSITION = "/workflow/transition",
    USER_WORKFLOW_WORKFLOW = "/workflow/workflow",

    USER_PATH = "/user-management",
    USER_DEFAULT_LIST_PATH = "user-management/users",
    ROLE_DEFAULT_LIST_PATH = "user-management/roles",
    USER_TYPE_DEFAULT_LIST_PATH = "user-management/user-type",
    DASHBOARD_PATH = "/dashboard",
    VALUATION_PROPERTY = 'valuation/property/cards',
    USER_LIST_PATH = "list",
    USER_ADD_PATH = "add",
    USER_LIST_REDIRECT_PATH = "user-management/users",
    PRIVATE_LIST_REDIRECT_PATH = "/user-management/roles",
    ROLE_LIST_REDIRECT_PATH = "user-management/roles",
    USER_ADD_REDIRECT_PATH = "/add",
    USER_VIEW_REDIRECT_PATH = "/view/",
    USER_EDIT_REDIRECT_PATH = "/edit/",


    /* Sample base  */
    SAMPLE_LIST_PATH = "/basic/",

    /* user management - entity */
    ENTITY_LIST_REDIRECT_PATH = "/user-management/user-type",

    /* user management - roles */
    ROLES_LIST_REDIRECT_PATH = "/user-management/roles",


    DMS_TREE_ADD_PATH = "dmstree/create/",
    DMS_TREE_LIST_PATH = "dmstree/",

    /*Configuration*/
    DASHBOARD = 'dashboard/list',
    CONFIGURATION_PATH = "/configuration",
    LOCATION_PATH = "/location/location-list",
    LEVEL_LIST_PATH = "/location/level-list",
    CATEGORY_LIST_PATH = "/location/category-list",

    /**PRODUCT MANAGEMENT */
    PRODUCT_PATH = "/product",
    PRODUCT_TYPE_LIST_PATH = "/product/producttype-list",
    PRODUCT_CATEGORY_LIST_PATH = "/product/productcategory-list",
    PRODUCT_UOM_LIST_PATH = "/product/productuom-list",
    PRODUCT_PACKAGE_LIST_PATH = "/product/productpackage-list",




    /* SCP MANAGEMENT */
    SCP_PATH = "/scp-mgmt/scp-registration",
    ONBOARD_SCP_PATH = "/scp-mgmt/scp-onboard",
    SCP_LIST = "/scp-mgmt/scp-list",
    SCP_APPLICATION_PATH = "/scp-mgmt/application-status",
    SELF_REGISTER_PATH = "/self-register/",

    // tax management
    TAX_STAMP_LIST = "/tax-management/stamp/list",
    TAX_SLAB_LIST = "/tax-management/slab/list",

    GRIEVANCE_SLAB_LIST = "/grievance-management/list",
    ORDER_LIST = "/order/list",
    FORECAST_LIST = `/forecast/list`,
    AUDIT_LOG = "/reports/audit-log",


    //SCP PRODUCT
    PRODUCT_SCP_LIST_PATH = "/product/product-list",
}

export const MODULES_ROUTE_PROPERTIES = {
    AUTH_MODULE: {
        FORGOT: {
            PATH: routePath?.FORGOT_PATH,
            TITLE: "Forgot",
        },
        RESET: {
            PATH: routePath?.RESET_PATH,
            TITLE: "Reset Password",

            PARAM_PATH: routePath?.RESET_PARAM_PATH,
        },
        LOGIN: {
            PATH: routePath?.LOGIN_PATH,
            TITLE: "Login",
        },

    }
}

/* regex expressions */

export const REPLACE_GLOBAL_UNDER_SCORE = /_/g;
export const EMAIL_ID_REGEX = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export const MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/
export const MOBILE_9_REGEX = /^(\+\d{1,3}[- ]?)?\d{9}$/
export const PINCODE_REGEX = /[1-9][0-9]{5}$/
export const ALPHANUMERIC = /^[A-Za-z0-9 ]+$/
export const NUMERICWITHDECIMAL = /^[0-9\.\-_]*$/
export const ONLY_NUMBERS_REGEX = /^\d+$/
export const ONLY_STRING_REGEX = /^[a-zA-Z]+$/
export const NON_ZERO_CHECK = /^[1-9][0-9]*$/
export const DATE_FORMAT_REGEX = /^\d{2}\/\d{2}\/\d{4}$/
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)([_.-@!#$])?[A-Za-z\d_.-@!#$]{8,20}$/
export const PASSWORD_STRENGTH_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{5,}$/
export const SLUG_CHECK = /^[a-zA-Z0-9_]*$/
export const HIDENOTIFY = "hidenotify"
/* input character Limit */

export const MAXIMUM_CHARACTER_LIMIT_250 = 250
export const PHONE_NUMBER_MAX_LENGTH = 9

export enum MENU_UNIQ_NAME {
    DASHBOARD = "dashboard",
    AUDIT_LOG = "audit-log",
    USERS = "user-management",
    SCP = "scp-mgmt",
    REGISTRATION_SCP = "scp-registration",
    ONBOARD_SCP = "scp-onboard",
    SCP_LIST = "scp-list",

    PRODUCT = "product",
    PRODUCT_TYPE_LIST = "producttype-list",
    PRODUCT_CATEGORY = "productcategory-list",
    PRODUCT_UOM = "productuom-list",
    PRODUCT_PACKAGE = "productpackage-list",
    PRODUCT_SCP = "productscp-list",

    WORKFLOW = "workflow",
    EMAIL_TEMP = "email-template",
    CONFIGURATION = "configuration",
    LOCATION = "location",
    LEVEL_LIST = "level-list",
    CATEGORY_LIST = "category-list",
    LOCATION_LIST = "location-list",

    TAX_MANAGEMENT = "tax-management",
    GRIEVANCE_MANAGEMENT = "grievance-management",
    ORDER_MANAGEMENT = "order",
    FORECAST = "forecast",
    FORECAST_SCP = "raise-forecast",
    FORECAST_REQ = "forecast-request",
    STAMP_LIST = "stamp",
    SLAB_LIST = "slab",
    SCP_PRODUCT = "scp-product",

    REPORTS = "reports",
}

export enum scp {
    registration = 1,
    onboard = 2,
    scp_list = 3,
}

export enum USERTYPE {
    ADMIN_ID = 1,
    SCP_ID = 2,
    ADMIN_SLUG = 'admin',
    SCP_SLUG = 'scp',

}
export enum PRODUCTTYPE {
    SCP_PRODUCT_ID = 1,
}

export enum SCREENMODE {
    ADD = 1,
    EDIT = 2,
    DELETE = 3,
    VIEW = 4,
    LIST = 5,

    RAISE = 6,
    REQUEST = 7,

    REGISTRATION = 8,
    ONBOARDING = 9,

    RESET_PASSWORD = 10,
    CHANGE_PASSWORD = 11
}

export enum USERMANAGEMENT {
    USER = 1,
    ROLE = 2,
    ENTITY = 3
}

export enum FORECASTMANAGEMENT {
    LONG = 1,
    SHORT = 2,
    MARKING = 3
}

export enum USERGROUPS {
    SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR = "supply_chain_participant_admin"
}

export enum SCP_ONBOARD_REQUEST {
    SITE_DETAILS = 0,
    BANK_DETAILS = 1,
    SUPPLY_CHAIN_DETAILS = 2,
    USER_DETAILS = 3,
    OTHER_INFO = 4
}

export enum registration {

    REGISTRATION_APPROVE = "registration_approve"
}

export enum onboardingStepper {
    site = 0,
    user = 1,
    supply = 2,
    bank = 3,
    others = 4
}

export const AUDIT_LOG_CONFIG = {
    grievence: {
        breadCrumbs: {
            title: "Audit Logs",
            view_title: "Grievance",
            view_subtitle: "Grievance Detail",
            href: "/grievance-management/view/"
        },
        apiUrl: `${Endpoints.GET_GRIEVANCE_HISTORY}`,
        viewDataUrl: `${Endpoints.GET_GRIEVANCE_WEB}`,
        displayFieldKey: 'code'
    },
    product: {
        breadCrumbs: {
            title: "Audit Logs",
            view_title: "Product",
            view_subtitle: "Product Detail",
            href: "/product/view/"
        },
        apiUrl: `${Endpoints.GET_PRODUCT_HISTORY}`,
        viewDataUrl: `${Endpoints.SCP_PRODUCT}`,
        displayFieldKey: 'display_name',
        hideAttachment: true
    },
    forecast: {
        breadCrumbs: {
            title: "Audit Logs",
            view_title: "Forecast",
            view_subtitle: "Forecast Detail",
            href: "/forecast/view/:refID/",
            refIDKey: "product_details?.tax_stamp?.tax_stamp"
        },
        apiUrl: `${Endpoints.GET_FORECAST_HISTORY}`,
        viewDataUrl: `${Endpoints.FORECAST_TAX_STAMP}`,
        displayFieldKey: 'code',
        hideAttachment: true
    },
    scp_registration: {
        breadCrumbs: {
            title: "Audit Logs",
            view_title: "SCP Registration",
            view_subtitle: "SCP Registration Detail",
            href: "/scp-mgmt/view-status/"
        },
        apiUrl: `${Endpoints.GET_SCP_REGN_HISTORY}`,
        viewDataUrl: `${Endpoints.GET_SCP_REGN_DETAIL}`,
        displayFieldKey: 'validation_info?.registration_code',
        hideAttachment: true
    },
    scp_onboard: {
        breadCrumbs: {
            title: "Audit Logs",
            view_title: "SCP Onboarding",
            view_subtitle: "SCP Onboarding Detail",
            href: "/scp-mgmt/view-onboarding-status/"
        },
        apiUrl: `${Endpoints.GET_SCP_ONBOARD_HISTORY}`,
        viewDataUrl: `${Endpoints.GET_SCP_REGN_DETAIL}`,
        displayFieldKey: 'validation_info?.registration_code',
        hideAttachment: true
    }
}

export const FORCAST_CONFIG = {
    longRectAngularPaperStamp: {
        id: 1
    },
    shortRectAngularPaperStamp: {
        id: 2
    },
    markingStamp: {
        id: 3
    }
}
