import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMethod, BaseURL, colorCodes, Endpoints, ErrorCodes, ErrorKeys, ErrorMessage } from '../utils/constants';
import { catchError, tap } from 'rxjs/operators';
import { SnackbarService } from '../snackBar/snackbar.service';
import { Subject, throwError } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  invalidUserCredentials: any = new Subject<any>();

  constructor(
    private http: HttpClient,
    private _snackBar: SnackbarService,
    private _storageService: StorageService,
    private _loader: LoaderService,
  ) {

  }

  requestCall(api: Endpoints, method: ApiMethod, data?: any, options?: any) {
    let response: any;
    switch (method) {

      case ApiMethod.GET:
        response = this.http.get(`${environment.apiUrl}${api}`, options);
        break;

      case ApiMethod.POST:
        response = this.http.post(`${environment.apiUrl}${api}`, data, options);
        break;

      case ApiMethod.PUT:
        response = this.http.put(`${environment.apiUrl}${api}`, data, options);
        break;
      case ApiMethod.PATCH:
        response = this.http.patch(`${environment.apiUrl}${api}`, data, options);
        break;
        
      case ApiMethod.DELETE:
        response = this.http.delete(`${environment.apiUrl}${api}`, options);
        break;

    }
    return response;
  }



  thirdPartyRequestCall(apiBaseURL: BaseURL, api: Endpoints, method: ApiMethod, data?: any, options?: any) {
    let response: any;
    let apiUrl: any = apiBaseURL ? apiBaseURL : environment.apiUrl;
    switch (method) {

      case ApiMethod.GET:
        response = this.http.get(`${apiUrl}${api}`, options);
        break;

      case ApiMethod.POST:
        response = this.http.post(`${apiUrl}${api}`, data, options);
        break;

      case ApiMethod.PUT:
        response = this.http.put(`${apiUrl}${api}`, data, options);
        break;

      case ApiMethod.PATCH:
        response = this.http.patch(`${apiUrl}${api}`, data, options);
        break;

      case ApiMethod.DELETE:
        response = this.http.delete(`${apiUrl}${api}`, options);
        break;
    }

    return response;
  }

  handleError(error: HttpErrorResponse): any {
    this._loader.hide();
    if (error.error instanceof ErrorEvent) {
      console.log("Error occured", error?.error?.message, error)
    }
    else if (error) {
      console.log("Error occured:", error?.error?.detail, error)
      this.whichError(error?.status, (error?.error?.message || error?.error?.detail) ? (error?.error?.message ? error?.error?.message : error?.error.detail) : this.formatError(error?.status, error?.error));
      return throwError(() => ({ error: error?.message, status: error?.status }));
    }
  }

  handleRefreshTokenExpiry(error: HttpErrorResponse) {
    this._snackBar.loadSnackBar(ErrorMessage.SESSION_EXPIRED, colorCodes.ERROR);
    this._storageService.clearStorageRedirect();
    return throwError(() => ({ error: error?.message, status: error?.status }));
  }

  formatError(errorStatus: any, error: any = {}) {
    let name = Object?.keys(error)[0];
    if (errorStatus == ErrorCodes.HTTP_400_BAD_REQUEST && name == ErrorKeys.NON_FIELD_ERRORS) {
      return error[name]
    } else if (typeof error === "string") {
      return error
    }
    return error[name];
  }


  whichError(errorCode: number, errorMessage: string) {
    if (ErrorCodes.INVALID_USER_CREDENTIALS == errorMessage) {
      this._snackBar.loadSnackBar(ErrorMessage.INVALID_USER_CREDENTIALS, colorCodes.ERROR);
      return;
    }
    errorMessage = errorMessage.endsWith('.') ? errorMessage.slice(0, -1) : errorMessage ;
    switch (errorCode) {
      case ErrorCodes.HTTP_400_BAD_REQUEST:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_403_FORBIDDEN:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_401_UNAUTHORIZED:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_404_NOT_FOUND:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_500_ERROR:
        this._snackBar.loadSnackBar(ErrorMessage.INTERNAL_SERVER_ERROR, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_CONN_REFUSED:
        this._snackBar.loadSnackBar(ErrorMessage.SERVER_UNAVAILABLE, colorCodes.ERROR);
        break;

      default:
        this._snackBar.loadSnackBar(ErrorMessage.UNKNOWN_ERROR_CODE, colorCodes.ERROR);
    }
  }



  refreshToken() {
    const refreshToken = this._storageService.geRefreshtToken();
    return this.http.post(`${environment.apiUrl}${Endpoints.POST_REFRESH_TOKEN_AUTH}`, { refresh: refreshToken }).pipe(
      tap((response: any) => {
        this._storageService.setToken(response?.data?.access);

      }),
      catchError((err) => this.handleRefreshTokenExpiry(err))
    )
  }
}
