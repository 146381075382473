import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor(private _router: Router) { }

  setToken(token: string) {
    localStorage.setItem('token', token);
    console.log(token);
    
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token)
  }

  getToken() {
    return localStorage.getItem('token');
  }

  geRefreshtToken() {
    return localStorage.getItem('refresh_token');
  }

  checkTokenAvailability() {
    return localStorage.getItem('token') ? true : false;
  }

  getPermssions() {
    if (JSON.parse(localStorage.getItem('permissions') || '{}').permissions) {
      return JSON.parse(localStorage.getItem('permissions') || '{}').permissions;
    }
    else {
      return JSON.parse(localStorage.getItem('permissions') || '{}');
    }
  }

  deleteToken() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('role');
    localStorage.removeItem('DWT_port');
    localStorage.removeItem('DWT_ssl');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('layerDetails');
  }

  
  deletePermission() {
    localStorage.removeItem('permissions');
  }

  setUserDetails(userDetails: any) {
    localStorage.setItem('user', userDetails);
  }

  setDetails(keyName:string = '', details: any) {
    localStorage.setItem(keyName, details);
  }

  getDetails(keyName:string = '') {
    localStorage.getItem(keyName);
  }

  setUserPermissions(permissions: any) {
    localStorage.setItem('permissions', permissions);
  }

  getUserDetails(): any {
    if (localStorage.getItem('user') === null) {
      this.clearStorageRedirect();
    }
    let userData: any = localStorage.getItem('user');
    return JSON.parse(userData);
  }

  clearStorageRedirect() {
    this.deleteToken();
    this._router.navigate(['auth']);
  }

}
