import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LodashService {

  Clonedata(cloneData: any) {
    return _.cloneDeep(cloneData);
  }

  sortUnique(sortUdata: any) {
    return _.sortedUniq(sortUdata);
  }
  
  trim(data: any) {
    return _.trim(data);
  }
  
  toLower(data: any) {
    return _.toLower(data);
  }
 
  hasData(data: any, fieldName:string) {
    return _.has(data, fieldName);
  }

  groupBy(data: any, fieldName:any) {
    return _.groupBy(data, fieldName);
  }
  
  getLodash() {
    return _;
  }

  mapData(data: any, fieldName:string) {
    return _.map(data, fieldName);
  }

  mapDataFn(data: any, fn:any) {
    return _.map(data, fn);
  }

  getData(data: any, fieldName:string, defaultVal: any = '') {
    return _.get(data, fieldName, defaultVal);
  }

  isEmpty(data: any) {
    return _.isEmpty(data);
  }

  isArray(data: any) {
    return _.isArray(data);
  }

  isObject(data: any) {
    return _.isObject(data);
  }

  sortUniqueBy(sortUdata: any, sortValue: any) {
    return _.sortedUniqBy(sortUdata, [sortValue]);
  }

  unique(sortUdata: any) {
    return _.uniq(sortUdata);
  }

  includes(data:any, fieldName: any) {
    return _.includes(data, fieldName);
  }

  getValues(data:any) {
    return _.values(data);
  }

  loostIncludes(data:any, fieldVal: any) {
    return data.some((v:any) => v == fieldVal);
  }

  filterDataWithIncludes(data:any, filterFunction:any) {
    return _.filter(data, (o:any) => { 
      return filterFunction(o);
    });
  } 

  some(data:any, someFilterFunction:any) {
    return _.filter(data, (o:any) => { 
      return someFilterFunction(o);
    });
  } 

  orderBy(sortData: any, sortKey: any, sortOrder: any) {
    return _.orderBy(sortData, [sortKey], [sortOrder]);
  }

  sumBy(data:any, fieldName: any) {
    return _.sumBy(data, (o:any) => o[fieldName] );
  }

  findIndex(data:any, fieldVal: any) {
    return _.findIndex(data, function(o:any) { return o == fieldVal; });
  }

  find(data:any, fieldName: any, fieldVal: any) {
    return _.find(data, function(o:any) { return o[fieldName] == fieldVal; });
  }

  findIndexBy(data:any, fieldName: any, fieldVal: any) {
    return _.findIndex(data, function(o:any) { return o[fieldName] == fieldVal; });
  }

  removeFromArray(data:any = [], matchedVal: any) {
    return _.remove(data, (o) => o != matchedVal);
  }

  pick(obj:any = {}, fields: any =[]) {
    return _.pick(obj, fields);
  }

  omit(obj:any = {}, fields: any =[]) {
    return _.omit(obj, fields);
  }

  kebabCase(text:any){
    return _.kebabCase(_.deburr(text)).toLowerCase();
  }
}
